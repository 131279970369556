import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse,
} from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { map, take, retryWhen, catchError } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { Profile } from "../model/profile";
import * as crypto from "crypto-js";

let authtoken = "";
let isLoggedIn = localStorage.getItem("isLoggedIn");

const secret = "@AKIAJSN26KWXJ45CJJRFUA@";
// if(isLoggedIn == "true"){
//  authtoken = localStorage.getItem('token');
// }

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    /* 'Authorization': authtoken */
  }),
};

@Injectable({
  providedIn: "root",
})

//Calling To Service Endpoints
export class CommonService {
  public API_URL = environment.API_BASE_URL;
  request: any = {};
  constructor(private http: HttpClient) {}

  userlogin(param: any): Observable<any> {
    return this.http.post<any>(this.API_URL + "/userLogin", param, httpOptions);
  }

  forgotPass(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/forgotPassword",
      param,
      httpOptions
    );
  }

  resetPass(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/resetPassword",
      param,
      httpOptions
    );
  }
  ChangePassword(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/changePassword",
      param,
      httpOptions
    );
  }

  getProjectData(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getProjectData",
      param,
      httpOptions
    );
  }

  GetFeedbackProjectData(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/GetFeedbackProjectData",
      param,
      httpOptions
    );
  }

  removeInitiativeRow(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/deleteProjectInitiative",
      param,
      httpOptions
    );
  }

  GetActiveProjectDashboard(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getDashboardData",
      param,
      httpOptions
    );
  }

  GetOwnerByOrganizationList(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getUserByRole",
      param,
      httpOptions
    );
  }
  getOrgByUser(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getOrgByUser",
      param,
      httpOptions
    );
  }

  getOwnerbyOrganizationResult(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getUserByRole",
      param,
      httpOptions
    );
  }

  updateTaskPeriod(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/updateTaskPeriod",
      param,
      httpOptions
    );
  }

  UpdateProjectUserStatus(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/updateProjectUserStatus",
      param,
      httpOptions
    );
  }
  UpdateProjectUserRole(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/updateProjectUserRole",
      param,
      httpOptions
    );
  }
  
  searchUsersListForProject(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/searchUsersForProject",
      param,
      httpOptions
    );
  }
  searchPrincipleForProject(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/searchPrincipleForProject",
      param,
      httpOptions
    );
  }

  GetUserRoleList(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getUserRoles",
      param,
      httpOptions
    );
  }

  createProject(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addProject",
      param,
      httpOptions
    );
  }
  deleteTaskPeriod(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/deleteTaskPeriod",
      param,
      httpOptions
    );
  }

  addUpdateExtraColumn(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addUpdateExtraColumn",
      param,
      httpOptions
    );
  }

  addExtraColumnValue(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addExtraColumnValue",
      param,
      httpOptions
    );
  }

  checkEmailExist(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/checkEmail",
      param,
      httpOptions
    );
  }

  editProjectSetting(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getProjectSetting",
      param,
      httpOptions
    );
  }

  sfrCompareMonth(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/sfrCompareMonth",
      param,
      httpOptions
    );
  }

  GetUserListingProjectwise(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getProjectUsers",
      param,
      httpOptions
    );
  }

  addOrganization(param: any): Observable<any> {
    return this.http.post<any>(this.API_URL + "/addUser", param, httpOptions);
  }

  inviteFeedbackUser(param: any): Observable<any> {
    return this.http.post<any>(this.API_URL + "/inviteFeedbackUser", param, httpOptions);
  }

  addUserInProject(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addProjectUser",
      param,
      httpOptions
    );
  }
  addRatingAndCommentTask(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addRatingAndCommentTask",
      param,
      httpOptions
    );
  }
  addRatingComment(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addRatingComment",
      param,
      httpOptions
    );
  }
  getSfrProjectData(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getSfrProjectData",
      param,
      httpOptions
    );
  }
  addSfrQuestion(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addSfrQuestion",
      param,
      httpOptions
    );
  }
  changeOrder(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/changeOrder",
      param,
      httpOptions
    );
  }
  saveSettings(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/saveSetting",
      param,
      httpOptions
    );
  }
  getSetting(): Observable<any> {
    return this.http.post<any>(this.API_URL + "/getSetting", "", httpOptions);
  }

  checkEmailTrusted(email: any, device_id : any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/isEmailTrusted",
      { email: email , 
        device_id : device_id
      },
      httpOptions
    );
  }

  addStrategicPriority(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addStrategicPriority",
      param,
      httpOptions
    );
  }
  deleteStrategicPriority(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/deleteStrategicPriority",
      param,
      httpOptions
    );
  }
  addUpdatePriorityInitative(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addUpdatePriorityInitative",
      param,
      httpOptions
    );
  }

  addUpdateProjectPeriod(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addUpdateProjectPeriod",
      param,
      httpOptions
    );
  }
  getRatingAndComment(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/getRatingAndComment",
      param,
      httpOptions
    );
  }
  addUpdateProjectTask(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/addUpdateProjectTask",
      param,
      httpOptions
    );
  }
  /* copyProject(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/copyProject",
      param,
      httpOptions
    );
  } */
  apiCall(param: any, action: any): Observable<any> {
    return this.http.post<any>(this.API_URL + "/" + action, param, httpOptions);
  }
  
  apiCallPdf(param: any): Observable<any> {
    //return this.http.post<any>('https://adelantepdftesting.24livehost.com/api/', param, httpOptions);
    return this.http.post<any>(
      "https://download.theadelantegroup.com/api/",
      param,
      httpOptions
    );
  }

  ValidateToken(param: any): Observable<any> {
    return this.http.post<any>(
      this.API_URL + "/validateToken",
      param,
      httpOptions
    );
  }
  encrypt(data) {
    return crypto.AES.encrypt(JSON.stringify(data), secret, {
      keySize: 128 / 8,
      iv: secret,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    }).toString();
  }

  decrypt(data) {
    return JSON.parse(
      crypto.enc.Utf8.stringify(
        crypto.AES.decrypt(data, secret, {
          keySize: 128 / 8,
          iv: secret,
          mode: crypto.mode.CBC,
          padding: crypto.pad.Pkcs7,
        })
      )
    );
  }
  setCurrentUser(currentUser: Profile): void {
    localStorage.setItem("SPcurrentUser", this.encrypt(currentUser));
  }
  getCurrentUser(): Profile {
    let data = localStorage.getItem("SPcurrentUser");
    if (data) {
      return new Profile(this.decrypt(data));
    }
    return null;
  }
}
