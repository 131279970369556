<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8" *ngIf="activeProjectId">
                    <h3>Project Settings</h3>
                </div>
                <div class="col-lg-10 col-xl-8" *ngIf="!activeProjectId">
                    <h3 *ngIf="gType == 1 || gType == 2 || pType == 1 || pType == 2">Project Settings - New project from - template
                        <!--  <span *ngIf="gType == 1">(Individual</span>
                        <span *ngIf="gType == 2">(Group</span> -->
                        <span *ngIf="pType == 1">(SOP)</span>
                        <span *ngIf="pType == 2">(SFR)</span>
                    </h3>
                    <h3 *ngIf="!gType && !pType">Project Settings - New project from - Scratch
                    </h3>
                </div>
            </div>
        </div>

        <div class="white_box-body">
            <app-growler></app-growler>
            <div class="row">
                <div class="col-md-12 text-right">

                    <!-- <button data-toggle="modal" data-target="#backConfirmation" type="button" class="btn btn-primary themeBtnBlue btn-sm">Back</button> -->

                </div>
            </div>

            <form class="form-horizontal form-material" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onProjectSubmit(f.form)" novalidate>
                <div class="row justify-content-between">
                    <div class="col-md-7">
                        <div class="form-group">
                            <label>PROJECT NAME<span class="req"> *</span></label>
                            <input trim="blur" placeholder="Project Name" type="text" maxlength="100" class="form-control" name="projectName" [(ngModel)]="Project.projectName" #projectName="ngModel" (input)="onKeyPress()" required>
                            <div *ngIf="f.submitted && !projectName.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project Name field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group m-0">
                            <label>ORGANIZATION <span class="req"> *</span></label>
                        </div>
                        <div class="input-group mb-2 mr-sm-2">
                            <select name="organization" class="form-control" name="organization" [(ngModel)]="Project.organization" #organization="ngModel" required (change)="changeOrganization($event.target)">
                <!-- <option>--Select--</option> -->
                <option *ngFor="let key of organizationList" [value]="key.id">
                    <!-- [value]="key.id" -->
                  {{ key.user_name }}</option>
              </select>
                            <div class="input-group-prepend" *ngIf="!is_client_participant">
                                <div class="input-group-text" data-toggle="modal" data-target="#addorganization" (click)="openAddOrdPopup()" id="btnOrganAddon"><i class="fa fa-plus"></i></div>
                            </div>
                            <a href="javascript:void(0)" id="closeAddOrgUserPopup" [hidden]="true" data-dismiss="modal" data-target="#addorganization">Close </a>


                            <div *ngIf="f.submitted && !organization.valid && !lastOrgId" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Organization field is required
                            </div>

                            <!-- <select name="organization"  class="form-control">
                <option *ngFor="let key of organizationList" [value]="key.id">{{ key.user_name }}</option>
            </select>  -->

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label>PROJECT TYPE <span class="req"> *</span></label>
                            <!-- disabled="true" -->
                            <select class="form-control" [disabled]="activeProjectId || projectAddFromTemplate" [(ngModel)]="Project.projectType" #projectType="ngModel" required name="projectType" (change)="changeProjectType($event.target)">
                <option [ngValue]="null">--Select--</option>
                <option [ngValue]="1">Strategic Operating Plan</option>
                <option [ngValue]="2">Stakeholder Feedback Report</option>
              </select>
                            <div *ngIf="f.submitted && !projectType.valid && !Project.projectType" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project Type field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>PROJECT OWNER <span class="req"> *</span></label>
                            <select class="form-control" id="projectOwner" [(ngModel)]="Project.projectOwner" #projectOwner="ngModel" required name="projectOwner" (change)="changeOwner()">
                                <option [value]="null">--Select--</option>
                                <!-- <option [value]="1">Admin</option> -->
                                <option *ngFor="let owner of ownerList" [value]="owner.id">
                                {{ owner.user_name }}</option>
                            </select>
                            <div *ngIf="f.submitted && !projectOwner.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project Owner field is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <div class="form-group">
                            <label>INDIVIDUAL / GROUP<span class="req"> *</span></label>
                            <select class="form-control" [disabled]="activeProjectId || projectAddFromTemplate" [(ngModel)]="Project.group" #group="ngModel" required name="group">
                            <option [ngValue]="null">--Select--</option>
                            <option [ngValue]="1">Individual</option>
                            <option [ngValue]="2">Group</option>
                        </select>
                            <div *ngIf="f.submitted && !group.valid && !Project.group" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project Individual/Group field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label> &nbsp;</label>
                        <div class="custom-control custom-checkbox" *ngIf="!is_client_participant">
                            <input type="checkbox" [(ngModel)]="Project.projectActive" #projectActive="ngModel" class="custom-control-input" id="projectActive" name="projectActive" (change)="onKeyPress()">
                            <label class="custom-control-label" for="projectActive">Project Active?</label>
                            <!-- <div *ngIf="f.submitted && !projectActive.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Project active field is required
                            </div> -->
                        </div>

                        <div class="custom-control custom-checkbox" *ngIf="is_client_participant">
                            
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="Project.group == 2">
                        <label>TEAM LEADER <span class="req"> *</span></label>
                        <input trim="blur" maxlength="100" type="text" name="teamLeader" [(ngModel)]="Project.teamLeader" #teamLeader="ngModel" required class="form-control" (input)="onKeyPress()" />
                        <div *ngIf="f.submitted && !teamLeader.valid && Project.group == 2" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Team leader field is required
                        </div>
                    </div>

                </div>
                <div class="row">

                    <div class="col-md-5">
                        <div class="form-group" *ngIf="Project.projectType==2">
                            <label>CURRENT PERIOD <span class="req"> *</span></label>
                            <div class="input-group">
                                <!-- [minDate]="minDate" -->
                                <p-calendar [disabled]="showCalander" [readonlyInput]="true" placeholder="MM/YYYY" [(ngModel)]="Project.currentPeriod" name="currentPeriod" view="month" [showIcon]="false" dateFormat="MM/yy" class="monthCalendra" [yearNavigator]="true" yearRange="2020:2030"
                                    #currentPeriod="ngModel" (onSelect)="onKeyPress()"><span class="form-control"></span>
                                </p-calendar>
                            </div>


                            <div *ngIf="f.submitted && !currentPeriod.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Current period field is required
                            </div>

                        </div>
                    </div>

                    <div class="col-md-7 text-right">
                        <label> &nbsp;</label>
                        <div class="custom-control custom-checkbox" *ngIf="!is_client_participant">
                            <button (click)="confirm()" class="btn btn-light">Cancel</button> &nbsp;
                            <button *ngIf="isUpdate" type="submit" class="btn btn-primary">Save Project</button>

                            <button *ngIf="!isUpdate" (click)="viewPage(Project.group, Project.projectType)" class="btn btn-primary">Back To Project</button>
                        </div>

                        <div class="custom-control custom-checkbox" *ngIf="is_client_participant">
                            <button (click)="confirm()" class="btn btn-light">Cancel</button> &nbsp;
                          
                            <button  (click)="viewPage(Project.group, Project.projectType)" class="btn btn-primary">Back To Project</button>
                        </div>
                    </div>
                    <div class="col-md-12 text-right" *ngIf="isUpdate">
                        <p><span class="req">*</span>Please save the project before proceeding to adding users.</p>
                    </div>
                </div>

                <!-- <button type="submit" id="formSubmit" class="btn-submit">
        <span>Save</span>
    </button> -->
            </form>
            <!-- *ngIf="afterSave" -->
            <div class="row mt-5" id="afterSave" *ngIf="projectIdNotExist && !Project.isDummyUser">
                <div class="col-md-12">
                    <div class="table-responsive project_settings-table">

                        <table class="table ">
                            <thead>
                                <th style="width:10%; padding-left: 10px !important; white-space: nowrap;" *ngIf="Project.group == 2 && !is_client_participant">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1" (click)="checkAll($event)" [checked]="isStateChecked">
                                        <label class="custom-control-label" for="customCheck1"> Select all</label>
                                    </div>
                                </th>
                                <th style="width:15%;">User Name</th>
                                <th *ngIf="Project.group == 2" style="width:20%;">User Email</th>
                                <th *ngIf="Project.group == 1" style="width:20%;">User Email</th>
                                <th *ngIf="Project.group == 2" style="width:18%; white-space: nowrap;">Last email sent on</th>
                                <th style="width:18%;" class="text-center">User active in project ?</th>
                                <th *ngIf="Project.group == 2" style="width:20%;" class="text-center">Project Roles </th>
                                <th *ngIf="Project.group == 1" style="width:15%;" class="text-center">Project Roles </th>
                            </thead>
                            <tbody>

                                <tr *ngFor="let showUser of projectUserList">

                                    <td *ngIf="Project.group == 2 && !is_client_participant">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="checkcustomCheck{{showUser.id}}" name="userList1[]" [checked]="isUserChecked(showUser.email)" (click)="singleUserCheckUncheck($event, showUser)">
                                            <label class="custom-control-label" for="checkcustomCheck{{showUser.id}}"></label>
                                        </div>
                                    </td>

                                   
                                    <td>
                                        <p class="mb-0"> {{showUser.user_name}}</p>
                                        <span>{{showUser.title}} | {{showUser.orgination}}</span>
                                    </td>
                                    <td>
                                        <p class="mb-0"> {{showUser.email}}</p>

                                    </td>
                                    <td class="v-align-mid" *ngIf="Project.group == 2">
                                        <p class="mb-0 mailSentDate"><span *ngIf="showUser.email_sent !='0000-00-00 00:00:00'"> {{dateFormates(showUser.email_sent)}} </span></p>
                                    </td>
                                    <td class="text-center v-align-mid" *ngIf="!is_client_participant">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheck{{showUser.id}}" (click)="assignProjectToUser(showUser.is_active,showUser.id)" [checked]="showUser.is_active == 1">
                                            <label class="custom-control-label" for="customCheck{{showUser.id}}"></label>
                                        </div>
                                    </td>

                                    <td class="text-center v-align-mid" *ngIf="is_client_participant">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="customCheck{{showUser.id}}" [checked]="showUser.is_active == 1"  [disabled]="true">
                                            <label class="custom-control-label" for="customCheck{{showUser.id}}"></label>
                                        </div>
                                    </td>
                                    <td class="text-center v-align-mid minWidthSet" >

                                        <p class="mb-0"><span>{{showUser.role}}</span>
                                            <a href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,showUser.roleId) *ngIf="!is_client_participant">
                                                + Add/Update Role
                                            </a>
                                        </p>
                                        <!-- <p class="mb-0"><span *ngIf="showUser.roleId==1">Principal</span>
                                            <a *ngIf="(showUser.roleId==1)" href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,showUser.roleId)>
                                                + Add/Update Role
                                            </a>
                                        </p>
                                        <p class="mb-0"> <span *ngIf="showUser.roleId==2">Client User </span>
                                            <a *ngIf="(showUser.roleId==2)" href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,showUser.roleId)>
                                                + Add/Update Role
                                            </a>
                                        </p>
                                        <p class="mb-0"><span *ngIf="showUser.roleId==3">Client Participant </span>
                                            <a *ngIf="(showUser.roleId==3)" href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,showUser.roleId)>
                                                + Add/Update Role
                                            </a>
                                        </p>
                                        <p class="mb-0"><span *ngIf="showUser.roleId==4">Client Organisation </span>
                                            <a *ngIf="(showUser.roleId==4)" href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,showUser.roleId)>
                                                + Add/Update Role
                                            </a>
                                        </p> -->
                                        <!-- <p class="mb-0"><a *ngIf="(showUser.roleId=='')" href="javascript:void(0)" class="addNewUserRoleTextBox" (click)=setUserRole(showUser.id,0)>
                                            + Add/Update Role
                                        </a>
                                        </p> -->
                                    </td>

                                    
                                </tr>
                                <tr *ngIf="!is_client_participant">
                                    <td colspan="3">
                                        <input type="text" readonly (click)="addusermodelpopup()" id="btnGroupAddon" placeholder="+ Add New User" class="addNewUserTextBox">
                                        <input type="hidden" readonly data-toggle="modal" data-target="#addUserModal" id="btnGroupAddonhidden">
                                    </td>
                                    <td class="text-center" *ngIf="Project.group == 2">
                                        <div class="custom-control custom-checkbox">
                                        </div>
                                    </td>
                                    <td>
                                        <!-- <input type="text" class="form-control"> -->
                                    </td>
                                    <td *ngIf="Project.group == 2">
                                        <!-- <input type="text" class="form-control"> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-md-12 text-right" *ngIf="Project.group == 2 && !is_client_participant">
                    <span class="userSelectedText" *ngIf="(selectedUserList && selectedUserList.length>0) && (projectUserList && projectUserList.length>0)"> {{selectedUserList.length}} out of {{projectUserList.length}} users selected </span> <button class="btn btn-primary btn-sm" (click)="sendProjectEmail()"> Send Email </button>
                </div>

            </div>

        </div>

    </div>



    <!--Add User role for project assign -->
    <input type="hidden" data-toggle="modal" data-target="#addUserRole" id="showUserRolePopup" placeholder="+ Add/Update Role" class="addNewUserTextBox">

    <div data-backdrop="false" class="modal fade" id="addUserRole" tabindex="-1" role="dialog" aria-labelledby="addUserRole" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0">Add/Update Role</h3>
                    <button type="button" id="addUserRoleHide" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-4">
                    <div class="row">
                        <div class="col-md-12">
                            <label>User Role</label>
                            <div class="input-group mb-2 mr-sm-2">
                                <select class="form-control" name="usertitle" id="usertitle" [(ngModel)]="UserRoles.roleId" #roleId="ngModel" (change)="changeRole($event)" required>
                                    <option [value]="0">-Please Select-</option>
                                        <option *ngFor="let role of roleList" [value]="role.id">
                                            {{ role.role }}</option>
                                    </select>
                                <div *ngIf="forguser.submitted && !userRole.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User Role field is required
                                </div>

                                <div class="input-group-prepend">
                                    <div class="input-group-text" data-toggle="modal" (click)="addUpdateUserRole()">Add/Update</div>
                                </div>
                            </div>
                            <div *ngIf="notValid" class="alert alert-danger mt-2">
                                <strong>{{message}}.</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>

    <!--Add User for project assign -->
    <div data-backdrop="false" class="modal fade" id="addUserModal" tabindex="-1" role="dialog" aria-labelledby="addUserModal" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0" id="addUserModal">Add New User</h3>
                    <button type="button" id="addNewUserHide" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body py-4">
                    <div class="row">
                        <div class="col-md-12">
                            <label>Select User<span class="req">*</span></label>

                            <div class="input-group mb-2 mr-sm-2">

                                <ng-select class="form-control" [items]="searchUsers" bindValue="id" bindLabel="fullDetails" placeholder="Select user" [(ngModel)]="selectedUser">
                                </ng-select>


                                <div class="input-group-prepend">
                                    <div class="input-group-text" data-toggle="modal" (click)="selectedUserAdd()">Add</div>
                                </div>

                            </div>
                            <div *ngIf="!projectIdNotExist" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Please add project.
                            </div>

                            <div *ngIf="notSelected" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User field is required
                            </div>


                            <div class="py-6 buttonPOsSetBt">
                                <input type="text" readonly data-toggle="modal" (click)="onAddNewUserDirect()" placeholder="+ Add New User" class="addNewUserTextBox">
                                <div class="" *ngIf="ShowHideUserAddFormVar == 2">

                                    <form class="" name="form" #forguser="ngForm" (ngSubmit)="forguser.form.valid && onAddUser(forguser.form)" novalidate>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Job Title<span class="req">*</span></label>
                                                    <input trim="blur" type="text" maxlength="50" name="usertitle" [(ngModel)]="Adduserorganization.usertitle" #usertitle="ngModel" required class="form-control" />
                                                    <div *ngIf="forguser.submitted && !usertitle.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User title field is required
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Name<span class="req">*</span></label>
                                                    <input trim="blur" type="text" maxlength="50" name="username" [(ngModel)]="Adduserorganization.username" #username="ngModel" required class="form-control" />
                                                    <div *ngIf="forguser.submitted && !username.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User name field is required
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Email <span class="req">*</span></label>
                                                    <input type="text" trim="blur" maxlength="100" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="useremail" [(ngModel)]="Adduserorganization.useremail" #useremail="ngModel" required class="form-control" />
                                                    <div *ngIf="forguser.submitted && !useremail.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Email field is invalid
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>User Role<span class="req">*</span></label>
                                                    <select class="form-control" name="userRole" id="userRole" [(ngModel)]="Adduserorganization.userRole" #userRole="ngModel" required name="userRole">
                            <option *ngFor="let role of roleList" [value]="role.id">
                              {{ role.role }}</option>
                          </select>
                                                    <div *ngIf="forguser.submitted && !userRole.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User Role field is required
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <input type="text"  data-toggle="modal" (click)="adduser()" id="btnGroupAddon" placeholder="+ Add New User" class="addNewUserTextBox"> -->
                                            <div class="col-md-12 text-right">
                                                <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Add User</button>
                                            </div>

                                        </div>

                                    </form>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>

    <!--Confirmation dialog box -->
    <div class="modal fade" id="backConfirmation" tabindex="-1" role="dialog" aria-labelledby="backConfirmation" aria-hidden="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0">Please confirm..</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body py-4">
                    <div class="row">
                        <div class="col-md-12">
                            Do you really want to ... ?
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                    <button type="button" (click)="confirm()" data-dismiss="modal" class="btn btn-primary">Ok</button>
                </div>
            </div>
        </div>
    </div>



    <!-- Add Organization Modal -->

    <div data-backdrop="false" class="modal fade" id="addorganization" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title m-0" id="exampleModalLabel">Add Organization and Users</h3>
                    <!-- {{userlist | json}} -->
                    <button type="button" id="buttonClosePop" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body py-4 buttonPOsSetBt">
                    <form class="" name="form" #forg="ngForm" (ngSubmit)="forg.form.valid && onorganizationSubmit(forg.form)" novalidate>

                        <label><strong>Add Organization:</strong></label>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Organization Name<span class="req">*</span></label>
                                    <input trim="blur" type="text" maxlength="50" class="form-control" name="orgname" [(ngModel)]="Organization.orgname" #orgname="ngModel" required>
                                    <div *ngIf="forg.submitted && !orgname.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Organization Name field is required
                                    </div>
                                    <!-- <input type="text" formControlName="orgname" maxlength="50" name="orgname" class="form-control" [ngClass]="{ 'is-invalid': submitted && formsub.orgname.errors }" /> -->
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Email</label>

                                    <input type="text" trim="blur" maxlength="100" class="form-control" name="orgemail" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" [(ngModel)]="Organization.orgemail" #orgemail="ngModel">
                                    <div *ngIf="forg.submitted && !orgemail.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Organization Email field is required
                                        <!-- <div *ngIf="orgemail.errors && orgemail.errors.pattern" class="invalid-feedback requied-message"><i class="fa fa-exclamation-triangle"></i> Please enter a valid Email
                  </div> -->
                                    </div>


                                </div>
                            </div>
                            <div class="formSubOuterBtn text-right">
                                <button type="submit" class="btn btn-primary themeBtnBlue">Save change</button>
                            </div>
                        </div>


                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->


                    </form>
                    <hr>
                    <!-- <button (click)="adduser()">Add input</button> -->

                    <label><strong>Add User:</strong><span>(Please add one default user)</span></label>
                    <form class="" name="Userform" #forguser="ngForm" (ngSubmit)="forguser.form.valid && onAddUser(forguser.form)" novalidate>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Job Title<span class="req">*</span></label>
                                    <input trim="blur" type="text" maxlength="50" name="usertitle" [(ngModel)]="organizationUserList.usertitle" #usertitle="ngModel" required class="form-control" />
                                    <div *ngIf="forguser.submitted && !usertitle.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User title field is required
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Name<span class="req">*</span></label>
                                    <input trim="blur" type="text" maxlength="50" name="username" [(ngModel)]="organizationUserList.username" #username="ngModel" required class="form-control" />
                                    <div *ngIf="forguser.submitted && !username.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User name field is required
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Email <span class="req">*</span></label>
                                    <input type="text" trim="blur" maxlength="100" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="useremail" [(ngModel)]="organizationUserList.useremail" #useremail="ngModel" required class="form-control" />
                                    <div *ngIf="forguser.submitted && !useremail.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Email field is invalid
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>User Role<span class="req">*</span></label>
                                    <select class="form-control" name="userRole" id="userRole" [(ngModel)]="organizationUserList.userRole" #userRole="ngModel" required name="userRole">
                    <option *ngFor="let role of roleList" [value]="role.id">{{ role.role }}</option>
                  </select>
                                    <div *ngIf="forguser.submitted && !userRole.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User Role field is required
                                    </div>
                                </div>
                            </div>

                            <!-- <input type="text"  data-toggle="modal" (click)="adduser()" id="btnGroupAddon" placeholder="+ Add New User" class="addNewUserTextBox"> -->
                            <div class="col-md-12 text-right">
                                <button type="submit" class="btn btn-primary themeBtnBlue">Add
                  User</button>
                            </div>

                        </div>

                    </form>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive normal_table-outer">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="userlistArray">
                                        <tr *ngFor="let user of userlistArray; index as i">
                                            <td>{{user.usertitle}}</td>
                                            <td>{{user.username}}</td>
                                            <td>{{user.useremail}}</td>
                                            <td>{{getRoleById(user.userRole)}}</td>
                                            <td>
                                                <a href="javascript:void(0)" (click)="deleteUser($event)"><i class="fa fa-times"
                            aria-hidden="true"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!userlistArray">
                                        <tr>
                                            <td colspan="3">No ecord Found</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>