export class Profile {
  public id: number;
  public organization_id: number;
  public orgnizationName: String = "";
  public user_name: any;
  public email: String = "";
  public company: String = "";
  public password: String = "";
  public new_password: String = "";
  public title: String = "";
  public profile_image: any;
  public intro: String = "";
  public bio: any;
  public token: string = "";
  public principal: any = [];
  public projects: any = [];
  public secondary_org_id: any = [];
  constructor(params: any) {
    Object.assign(this, params);
  }
}
