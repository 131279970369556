import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrowlerComponent, OverlayComponent } from './components/growler/growler.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { ProjectComponent } from './components/project/project.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { CommonModule } from "@angular/common";
import {ProjectSettingsComponent} from './components/project-settings/project-settings.component';
import { SfpProjectComponent } from './components/sfp-project/sfp-project.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientsComponent } from './components/clients/clients.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxTrimDirectiveModule } from "ngx-trim-directive";


import * as _moment from 'moment';
import { ApplicationSettingsComponent } from './components/application-settings/application-settings.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import { CalendarModule } from "primeng/calendar";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { SettingsComponent } from './components/settings/settings.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProjectViewComponent } from './components/project-view/project-view.component';
import { ProjectSfrComponent } from './components/project-sfr/project-sfr.component';
import { LogoComponent } from './components/logo/logo.component';
import { FeedbackSfrComponent } from './components/feedback-sfr/feedback-sfr.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { NgxPaginationModule } from "ngx-pagination";
import { MyProjectsComponent } from './components/my-projects/my-projects.component';
import { SopViewComponent } from './components/sop-view/sop-view.component';
import { SopCreateComponent } from './components/sop-create/sop-create.component';
import { FeedbackSfrPdfComponent } from './components/feedback-sfr-pdf/feedback-sfr-pdf.component';
import { SopViewPdfComponent } from './components/sop-view-pdf/sop-view-pdf.component';
import { ThanksComponent } from './components/thanks/thanks.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { ConfirmationDialougModalComponent } from './confirmation-dialoug-modal/confirmation-dialoug-modal.component';
import { ModalComponent } from './modal/modal.component';
import { SopFeedbackViewComponent } from './components/sop-feedback-view/sop-feedback-view.component';
import { ToastrModule } from 'ngx-toastr';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GrowlerComponent,
    NavBarComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ProjectComponent,
    ResetpasswordComponent,
    ProjectSettingsComponent,
    SfpProjectComponent,
    ClientsComponent,
    MyProfileComponent,
    ApplicationSettingsComponent,
    ProjectEditComponent,
    SpinnerComponent,
    OverlayComponent,
    ChangePasswordComponent,
    ProjectCreateComponent,
    SettingsComponent,
    ProjectViewComponent,
    ProjectSfrComponent,
    LogoComponent,
    FeedbackSfrComponent,
    FeedbackComponent,
    MyProjectsComponent,
    SopViewComponent,
    SopFeedbackViewComponent,
    SopCreateComponent,
    FeedbackSfrPdfComponent,
    SopViewPdfComponent,
    ThanksComponent,
    ConfirmationModalComponent,
    ConfirmationDialougModalComponent,
    ModalComponent,
    SopFeedbackViewComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    NgxTrimDirectiveModule,
    NgbModule,
    AccordionModule,
    CalendarModule,
    SlickCarouselModule,
    NgxPaginationModule,
    NgxOtpInputModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      newestOnTop: true
    }),
  ],
  exports:[
    ConfirmationModalComponent,
    ConfirmationDialougModalComponent,
    ModalComponent
  ],
  entryComponents:[
    ConfirmationModalComponent,
    ConfirmationDialougModalComponent,
    ModalComponent
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
