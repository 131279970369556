import {
  Component,
  OnInit,
  ViewChild,
  ɵConsole,
  ComponentFactoryResolver,
} from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { Project } from "../../model/project";
import { Organization } from "../../model/organization";
import { Adduserorganization } from "../../model/adduserorganization";
import * as $ from "jquery";
import { forkJoin, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-project-settings",
  templateUrl: "./project-settings.component.html",
  styleUrls: ["./project-settings.component.scss"],
})
export class ProjectSettingsComponent implements OnInit {
  @ViewChild("forguser") forguser; // Added this
  @ViewChild("forg") forg; // Added this
  @ViewChild("closebutton") closebutton;
  projectsettingForm: FormGroup;
  organizationList: object;
  date12: Date;
  minDate: Date;
  roleList: object;
  ownerList: object;
  projectUserList: any;
  participantList: any = [];
  selectedUserList: any = [];
  lastOrgId: boolean;
  saveOwnergId: any;
  saveOrgId: any;
  isLoggedIn: any;
  IsModelCondition: any = "none";
  organizationUserForm: FormGroup;
  organizationForm: FormGroup;
  submitted: boolean = false;
  projectActive: boolean = true;
  isStateChecked: boolean = false;
  notSelected: boolean = false;
  IsHidden: boolean = true;
  IsaddUserOrgbtnHidden: boolean = false;
  OrgUsersubmitted = false;
  afterSave: boolean = false;
  IsmodelShow: boolean = false;
  authtoken: any;
  reasonValue: object;
  setUserRoleData: object;
  ShowHideUserAddFormVar: any;
  projectsubmitted = false;
  organizationUserusubmitted = false;
  organizationsubmitted = false;
  hideOrgModal: boolean = false;
  hideProjectAssignModal: boolean = false;
  showCalander: boolean = false;
  userlist: any = [];
  UserRoles: any = {};
  organizationUserList: any = {};
  ownerArray: any = [];
  userlistArray: any = [];
  searchUsers: any = [];
  globalRoles: any = [];
  permission: any = [];
  date: any;
  pType: any;
  gType: any;
  tempOwner: any;
  notValid: boolean = false;
  modalVisible = false;
  loading: boolean = false;
  projectIdNotExist: boolean = false;
  createProjectID: any;
  activeProjectId: any;
  activeOrgId: any;
  temProjName: any;
  projectAddFromTemplate: boolean = false;
  isUpdate: boolean = false;
  projectOwnerData:any = [];
  loginUser : any;
  public Project: Project = {} as Project;
  public Organization: Organization = {} as Organization;
  public Adduserorganization: Adduserorganization = {} as Adduserorganization;
  //public project: Project = new Project({});
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    public growlerService: GrowlerService,
    private toastrService: ToastrService
  ) { }

  selectedUser: any;
  selectedUserName = "";
  selectedUserId: number;
  selectedUserIds: number[];
  public destinations = [];
  message: any;

  ngOnInit(): void {
    let roles = localStorage.getItem("roles");
    this.getPermissionByRole(roles);
    this.loginUser = localStorage.getItem("userId");
    //this.getPermissionByRole(roles);
    this.UserRoles = {
      roleId: 0,
    };
    var makeDate = new Date();
    makeDate = new Date(makeDate.setMonth(makeDate.getMonth() - 1));
    this.minDate = makeDate;
    //this.activeProjectId = this.route.snapshot.params.id;
    this.activeProjectId = localStorage.getItem("editProjectId");
    this.Project = {} as Project;
    this.Organization = {} as Organization;
    this.Adduserorganization = {} as Adduserorganization;
    this.ShowHideUserAddFormVar = "";
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.Project.projectOwner = null;
    this.isLoggedIn = localStorage.getItem("isLoggedIn");
    this.authtoken = localStorage.getItem("isLoggedIn");
    this.getUserRoleList();

    if (this.pType && this.gType) {
      this.projectFromTemplate();
    } else {
      this.projectFromScratch();
    }

    this.getOwnerProjectData(this.activeProjectId);

    

    if (this.activeProjectId) {
      this.editProjectSetting(this.activeProjectId);
    }

    if (
      this.gType == 1 ||
      this.gType == 2 ||
      this.pType == 1 ||
      this.pType == 2
    ) {
      this.projectAddFromTemplate = true;
      this.saveOrgId = parseInt(localStorage.getItem("orgId"));
      this.saveOwnergId = parseInt(localStorage.getItem("userId"));

      this.temProjName = "";
      if (this.gType == 1) {
        this.temProjName += "Individual";
      }
      if (this.gType == 2) {
        this.temProjName += "Group";
      }
      if (this.pType == 1) {
        this.temProjName += " SOP";
      }
      if (this.pType == 2) {
        this.temProjName += " SFR";
      }
      if (this.gType == 1) {
        this.temProjName += "- " + localStorage.getItem("username");
      }
      if (this.gType == 2) {
        this.temProjName += "- " + localStorage.getItem("orgnization_name");
      }

      this.Project.organization = parseInt(localStorage.getItem("orgId"));
      this.Project.projectOwner = parseInt(localStorage.getItem("userId"));

      this.Project.projectName = this.temProjName;
    }
    //console.log(this.checkPermission("sfr_update_period", this.permission));
  }

  is_client_participant : boolean = false;

  editProjectSetting(activeProjectId) {
    this.projectIdNotExist = true;
    let projectDetail = {
      projectId: activeProjectId,
    };
    this.commonService
      .editProjectSetting(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {
          //let projectData= projectSettingResultData["data"].ResponseData.data;
          this.Project = projectSettingResultData["data"].ResponseData.data[0];
          localStorage.setItem("projectName", this.Project.projectName);

          this.tempOwner =
            projectSettingResultData["data"].ResponseData.data[0].projectOwner;
          this.Project.currentPeriod = new Date(this.Project.currentPeriod);
          this.projectUserList =
          projectSettingResultData["data"].ResponseData.users;

          this.is_client_participant = this.projectUserList.find(user => user.id == this.loginUser)?.roleId == 2;

          console.log("this.projectUserList+++",this.projectUserList);

          console.log("this.is_client_participant+++",this.is_client_participant);
          this.getUserPermissionOnProject(activeProjectId);
          //this.ownerList = projectSettingResultData["data"].ResponseData.users;

          this.saveOwnergId = this.Project.projectOwner;
          this.saveOrgId = this.Project.organization;
          this.getOwnerOnUserLogin(this.saveOrgId, this.saveOwnergId);
          setTimeout(() => {
            this.searchPrincipleForProject(this.saveOrgId, activeProjectId);
          }, 1000);
          

          this.activeOrgId =
            projectSettingResultData["data"].ResponseData.data[0].organization;
        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }

  
  getOwnerProjectData(activeProjectId) {
    let data = {
      projectId: activeProjectId, //58
    };
    this.commonService.apiCall(data,"getOwnerProjectData").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.projectOwnerData = result["data"].ResponseData[0];
          console.log("this.projectOwnerData+++",this.projectOwnerData);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }

  searchPrincipleForProject(OrgId, ProjId) {
    this.loading = true;
    let PostDetail = {
      orgId: OrgId,
      projectId: ProjId,
    };

    this.commonService
      .searchPrincipleForProject(PostDetail)
      .subscribe((projectUsersResultData) => {
        this.loading = false;

        if (!projectUsersResultData["success"]) {
          this.message = projectUsersResultData["error"];
        }

        if (
          projectUsersResultData["success"] &&
          projectUsersResultData["data"].ResponseCode == 200
        ) {
          let currentOrgUser = projectUsersResultData["data"].ResponseData[0];
          let otherPrincipals = projectUsersResultData["data"].ResponseData[1];
    
          if(this.projectOwnerData.ownerId == this.loginUser){

            let secondaryOrgUser = projectUsersResultData["data"].ResponseData[2];

            console.log("secondaryOrgUser++++",secondaryOrgUser.length);

            let outputArray = secondaryOrgUser.filter(secItem => 
              !otherPrincipals.some(priItem => priItem.id == secItem.id)
          );
          
          console.log("outputArray++++",outputArray.length);

            let secondaryOrg = outputArray ? currentOrgUser.concat(outputArray) : currentOrgUser;
  
            this.searchUsers = secondaryOrg.concat(otherPrincipals);

          }
          else{

            this.searchUsers = currentOrgUser.concat(otherPrincipals);

          }
        
          //console.log(currentOrgUser);
          this.searchUsers.map((i) => {
            if (i.orgnization) {
              i.fullDetails = i.user_name + " (" + i.orgnization + ")";
            } else {
              i.fullDetails = i.user_name;
            }
            return i;
          });
        } else {
          this.message = projectUsersResultData["data"].ResponseMessage;
        }
      });
  }

  projectFromScratch() {
    this.Project.group = null;
    this.Project.projectType = null;
    this.Project.projectActive = true;
  }

  projectFromTemplate() {
    this.Project.group = parseInt(this.gType);
    this.Project.projectType = parseInt(this.pType);
    if (this.Project.projectType == "2") {
      this.Project.currentPeriod = new Date();
    }
    this.Project.projectActive = true;
  }
  changeProjectType(Type: any) {
    if (this.Project.projectType == "2" && !this.activeProjectId) {
      this.Project.currentPeriod = new Date();
    }
  }

  getOrganizationList() {
    this.loading = true;

    let data = {
      orgId: "",
      role: 4,
    };

    this.commonService
      .GetOwnerByOrganizationList(data)
      .subscribe((organizationResult) => {
        this.loading = false;
        if (!organizationResult["success"]) {
          this.message = organizationResult["error"];
        }
        if (
          organizationResult["success"] &&
          organizationResult["data"].ResponseCode == 200
        ) {
          this.organizationList = organizationResult["data"].ResponseData;
          //this.getUserRoleList();
        } else {
          this.message = organizationResult["data"].ResponseMessage;
        }
      });
  }
  getOrg() {
    this.loading = true;

    let data = {
      orgId: parseInt(this.Project.organization),
      role: 4,
    };

    console.log("this.Project.organization+++++",this.Project.organization);

    if (this.globalRoles && this.globalRoles.global_active_org) {
      data["orgId"] = 0;
    }
    this.commonService.getOrgByUser(data).subscribe((organizationResult) => {
      this.loading = false;
      if (!organizationResult["success"]) {
        this.message = organizationResult["error"];
      }
      if (
        organizationResult["success"] &&
        organizationResult["data"].ResponseCode == 200
      ) {
        this.organizationList = organizationResult["data"].ResponseData;
        //this.getUserRoleList();
      } else {
        this.message = organizationResult["data"].ResponseMessage;
      }
    });
  }

  getUserRoleList() {
    let data = {};
    this.commonService.GetUserRoleList(data).subscribe((roleResult) => {
      if (!roleResult["success"]) {
        this.message = roleResult["error"];
      }
      if (roleResult["success"] && roleResult["data"].ResponseCode == 200) {
        this.roleList = roleResult["data"].ResponseData;
      } else {
        this.message = roleResult["data"]
          ? roleResult["data"].ResponseMessage
          : "";
      }
    });
  }

  adduser(userlistParam: any = []) {
    this.userlistArray = userlistParam;
    this.IsaddUserOrgbtnHidden = false;
    // this.loading = false;
  }

  getformsaveproject() {
    return this.projectsettingForm.controls;
  }

  onAddNewUserDirect() {
    this.ShowHideUserAddFormVar = 2;
  }

 
onProjectSubmit(f: any) {
  this.loading = true;
  let self = this;
  if (this.activeProjectId) {
    this.Project.projectId = this.activeProjectId;
  }

  if (!this.Project.projectActive) {
    this.Project.projectActive = false;
  }
  if (!this.Project.teamLeader) {
    this.Project.teamLeader = "";
  }
  this.saveOwnergId = this.Project.projectOwner;

  let cDate = this.Project;

  let msg = !self.Project.projectId ? "Project added successfully." : "Project updated successfully.";

  // Creating an array of observables
  let operations = [
    this.commonService.createProject(self.Project),
    // Add other services or API calls here if needed, for example:
    // this.otherService.someMethod()
  ];

  // Using forkJoin to execute all observables in parallel
  forkJoin(operations).subscribe(results => {
    let result = results[0]; // Assuming createProject is the first operation

    if (!result["success"]) {
      this.message = result["error"];
      this.loading = false;
    } else if (result["success"] && result["data"].ResponseCode == 200) {
      this.projectIdNotExist = true;
      this.Project.projectId = result["data"].ResponseData[0].InprojectId;
      this.searchPrincipleForProject(self.Project.organization, this.Project.projectId);
      localStorage.setItem("editProjectId", result["data"].ResponseData[0].InprojectId);
      console.log("self.Project++++", self.Project);
      localStorage.setItem("projectName", self.Project.projectName);
      localStorage.setItem("pType", self.Project.projectType);
      localStorage.setItem("gType", self.Project.group);
      this.Project.projectOwner = self.Project.projectOwner;
      this.ngOnInit();
      this.growlerService.success(msg);
      this.growlerService.clear();
      
      if (this.Project.projectId) {
        //this.GetUserListingProjectwise(result.lastId);
      }
      this.projectsubmitted = true;
      this.isUpdate = false;
    } else {
      this.message = result["data"].ResponseMessage;
    }
    this.loading = false;
  });
}


  GetUserListingProjectwise(projectId: any) {
    this.loading = true;
    this.commonService
      .GetUserListingProjectwise(projectId)
      .subscribe((projectwiseUserListResult) => {
        this.loading = false;
        if (!projectwiseUserListResult["success"]) {
          this.message = projectwiseUserListResult["error"];
        }
        if (
          projectwiseUserListResult["success"] &&
          projectwiseUserListResult["data"].ResponseCode == 200
        ) {
          this.afterSave = true;
          this.projectUserList =
            projectwiseUserListResult["data"].ResponseData[0];



            console.log("this.projectUserList+++",this.projectUserList);

            
        } else {
          this.message = projectwiseUserListResult["data"].ResponseMessage;
        }
      });
  }

  openAddOrdPopup() {
    this.forg.resetForm();
    this.forguser.resetForm();
  }

  onAddUser(formUser: any) {
    this.loading = true;

    this.userlist = formUser.value;
    if (this.userlist.useremail) {
      let dataEmail = {
        email: this.userlist.useremail,
      };
      document.getElementById("addNewUserHide").click();
      this.commonService
        .checkEmailExist(dataEmail)
        .subscribe((emailCheckResult) => {
          
          if (!emailCheckResult["success"]) {
            this.message = emailCheckResult["error"];
          }

          this.IsaddUserOrgbtnHidden = true;
          if (emailCheckResult["data"].ResponseCode == 200) {
            // this.loading= false;
            const listArray = Object.assign({}, this.userlist);
            this.userlistArray.push(listArray);
            console.log("this.userlistArray+++++",this.userlistArray)
            this.adduser(this.userlistArray);
            this.forguser.resetForm();
           
            //this.Adduserorganization = {} as Adduserorganization;
            if (this.ShowHideUserAddFormVar == 2) {
              let selfuser = this;
              let data = {};
              if (this.activeProjectId) {
                data = {
                  organizationId: this.activeOrgId,
                  projectId: this.activeProjectId,
                  roleId: 2,
                  user: listArray,
                  projectName: localStorage.getItem("projectName"),
                  projectType: localStorage.getItem("pType"),
                };
              } else {
                data = {
                  organizationId: this.Project.organization,
                  projectId: this.Project.projectId,
                  roleId: 2,
                  user: listArray,
                  projectName: localStorage.getItem("projectName"),
                  projectType: localStorage.getItem("pType"),
                  //selectedUserId: "",
                  //User: selfuser.userlist
                };
              }

              let orgIdTemp = {
                value: this.Project.organization,
              };
              //this.changeOrganization(orgIdTemp);
              if (this.activeProjectId) {
                var pId = this.activeProjectId;
              } else {
                pId = this.Project.projectId;
              }
              //this.hideProjectAssignModal=true;
              //this.closebutton.nativeElement.click();
              this.commonService
                .addUserInProject(data)
                .subscribe((addUserInProjectResult) => {

                  console.log("addUserInProjectResult++++",addUserInProjectResult);
                  
                  if (!addUserInProjectResult["success"]) {
                    this.message = addUserInProjectResult["error"];
                  }
                  if (
                    addUserInProjectResult["success"] &&
                    addUserInProjectResult["data"].ResponseCode == 200
                  ) {
                   
                    this.projectUserList =
                      addUserInProjectResult["data"].ResponseData;
                      this.ngOnInit();
                      this.loading = false;
                    //window.location.reload();
                    //this.loading = false;
                  } else {
                    this.message =
                      addUserInProjectResult.error;
                      this.ngOnInit();
                      this.loading = false;
                  }
                });
            }
          } else {
            this.growlerService.error(
              "This email is already registered with the system."
            );
            this.growlerService.clear();
            this.loading = false;
          }
        });
    } else {
      this.loading = false;
    }
    //this.loading= false;
  }

  deleteUser(e: any) {
    this.userlistArray.splice(0, 1);
  }

  onClose(isVisible: boolean) {
    this.modalVisible = isVisible;
  }

  selectedUserAdd() {
    this.loading = true;
    let setOrgId = this.Project.organization;

    if (!this.selectedUser) {
      //this.notSelected = true;
      setTimeout(() => {
        this.notSelected = true;
      }, 1000);
    } else {
      this.notSelected = false;
    }

    if (this.Project.projectId) {
      this.createProjectID = this.Project.projectId;
    } else {
      this.notSelected = false;
      this.projectIdNotExist = true;
    }
    if (this.activeProjectId) {
      this.createProjectID = this.activeProjectId;
    }

    if (this.selectedUser) {
      let data = {
        organizationId: 0,
        projectId: this.createProjectID,
        selectedUserId: this.selectedUser,
        roleId: 2,
        projectName: localStorage.getItem("projectName"),
        projectType: localStorage.getItem("pType"),
      };
      document.getElementById("addNewUserHide").click();
      this.commonService
        .addUserInProject(data)
        .subscribe((addUserInProjectResult) => {
          this.loading = false;
          if (!addUserInProjectResult["success"]) {
            this.message = addUserInProjectResult["error"];
          }
          if (
            addUserInProjectResult["success"] &&
            addUserInProjectResult["data"].ResponseCode == 200
          ) {
            this.selectedUser = "";
            this.projectUserList = addUserInProjectResult["data"].ResponseData;
            console.log("this.projectUserList in selectedadduser++",this.projectUserList)
            var dataProject = {
              projectId: this.createProjectID,
            };
           
            this.ngOnInit();
            this.GetUserListingProjectwise(dataProject);
          } else {
            this.message = addUserInProjectResult["data"].ResponseMessage;
          }
        });
    } else {
      this.loading = false;
    }
  }

  getRoleById(id: any) {
    var role = "";
    if (id == "1") {
      role = "Principal";
    } else if (id == "2") {
      role = "Client User";
    } else if (id == "3") {
      role = "Client Participant";
    } else if (id == "4") {
      role = "Client Organisation";
    } else {
      role = "";
    }
    return role;
  }
  setUserRole(userId, roleId) {
    var projType =
      this.Project.projectType == 1 ? "sop_assign_roles" : "sfr_assign_roles";
    if (this.checkPermission(projType, this.permission)) {
      this.Project.projectOwner = this.tempOwner;
      alert("You do not have permissions to update user role");
      return false;
    }


    

    this.UserRoles.roleId = roleId;
    if (this.activeProjectId) {
      var projectIdForUserRoe = this.activeProjectId;
    } else {
      projectIdForUserRoe = this.Project.projectId;
    }
    this.setUserRoleData = {
      projectId: projectIdForUserRoe,
      userId: userId,
      roleId: roleId,
      orgId: parseInt(this.Project.organization)
    };

    document.getElementById("showUserRolePopup").click();
  }
  changeRole(e: any) {
    if (e.target.value) {
      this.setUserRoleData["roleId"] = e.target.value;
    }
  }
  addUpdateUserRole() {
    this.loading = true;
    this.setUserRoleData;
    console.log(this.setUserRoleData["roleId"]);
    if (this.setUserRoleData["roleId"] == "0") {
      this.notValid = true;
      this.message = "Please select user role to add/update.";
      this.loading = false;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      return;
    }

    document.getElementById("addNewUserHide").click();
    
    document.getElementById("addUserRoleHide").click();
    this.commonService
      .UpdateProjectUserRole(this.setUserRoleData)
      .subscribe((UpdateProjectUserRole) => {
        this.loading = false;
        
        if (!UpdateProjectUserRole["success"]) {
          
          this.message = UpdateProjectUserRole["error"];
          var dataProject = {
            projectId: this.setUserRoleData["projectId"],
          };
          this.GetUserListingProjectwise(dataProject);

          this.toastrService.error(UpdateProjectUserRole["data"].ResponseMessage);

        }
        if (
          UpdateProjectUserRole["success"] &&
          UpdateProjectUserRole["data"].ResponseCode == 200
        ) {
          var dataProject = {
            projectId: this.setUserRoleData["projectId"],
          };
          this.GetUserListingProjectwise(dataProject);
          this.toastrService.success("Successfully added/Updated Role.");
          this.growlerService.clear();
        } else {
          this.message = UpdateProjectUserRole["data"].ResponseMessage;
          var dataProject = {
            projectId: this.setUserRoleData["projectId"],
          };
          this.GetUserListingProjectwise(dataProject);

          this.toastrService.error(UpdateProjectUserRole["data"].ResponseMessage);
        }
      });
  }

  onorganizationSubmit(forg: any) {
    this.loading = true;
    this.organizationsubmitted = true;
    let data = {
      org_id: 0,
      name: this.Organization.orgname,
      email: this.Organization.orgemail,
      role: 4,
      users: this.userlistArray,
    };
    document.getElementById("buttonClosePop").click();
    if (this.Organization.orgemail) {
      let dataEmail = {
        email: this.Organization.orgemail,
      };
      this.commonService
        .checkEmailExist(dataEmail)
        .subscribe((emailCheckResult) => {
          this.loading = false;
          if (!emailCheckResult["success"]) {
            this.message = emailCheckResult["error"];
          }

          if (emailCheckResult["data"].ResponseCode == 200) {
            if (this.Organization.orgname && this.userlistArray.length != 0) {
              this.commonService
                .addOrganization(data)
                .subscribe((addOrganizationResult) => {
                
                  this.loading = false;
                  if (!addOrganizationResult["success"]) {
                    this.message = addOrganizationResult["error"];
                  }

                  if (
                    addOrganizationResult["success"] &&
                    addOrganizationResult["data"].ResponseCode == 200
                  ) {
                    this.organizationList =
                      addOrganizationResult["data"].ResponseData.data;
                    this.lastOrgId =
                      addOrganizationResult["data"].ResponseData.orgId;
                    this.Project.organization =
                      addOrganizationResult["data"].ResponseData.orgId;
                    let getOwner = {
                      orgId: this.lastOrgId,
                      role: 0,
                    };
                    this.commonService
                      .GetOwnerByOrganizationList(getOwner)
                      .subscribe((getOwnerbyOrganizationResult) => {
                        this.loading = false;
                        if (!getOwnerbyOrganizationResult["success"]) {
                          this.message = getOwnerbyOrganizationResult["error"];
                        }
                        if (
                          getOwnerbyOrganizationResult["success"] &&
                          getOwnerbyOrganizationResult["data"].ResponseCode ==
                          200
                        ) {
                          this.loading = false;
                          this.onKeyPress();
                          this.ownerList =
                            getOwnerbyOrganizationResult["data"].ResponseData;
                          localStorage.removeItem("pType");
                          localStorage.removeItem("gType");
                          this.growlerService.success(
                            "Successfully added organization."
                          );
                          this.growlerService.clear();
                          this.ownerArray = this.ownerList;
                          this.searchUsers = this.ownerList;

                          if (this.ownerList) {
                            this.Project.projectOwner =
                              this.ownerList[this.ownerArray.length - 1]["id"];
                          }
                        } else {
                          this.message =
                            getOwnerbyOrganizationResult[
                              "data"
                            ].ResponseMessage;
                          this.growlerService.error(this.message);
                          this.growlerService.clear();
                        }
                      });
                  } else {
                    this.message =
                      addOrganizationResult["data"].ResponseMessage;
                    this.growlerService.error(this.message);
                    this.growlerService.clear();
                  }
                });
            } else {
              this.loading = false;
              this.growlerService.error(
                "Please add atleast one user in this organization."
              );
              this.growlerService.clear();
            }
          } else {
            this.growlerService.error(
              "This Organization email is already registered with the system."
            );
            this.growlerService.clear();
          }
        });
    } else {
      if (this.Organization.orgname && this.userlistArray.length != 0) {
        this.commonService
          .addOrganization(data)
          .subscribe((addOrganizationResult) => {
            document.getElementById("buttonClosePop").click();
            this.loading = false;
            if (!addOrganizationResult["success"]) {
              this.message = addOrganizationResult["error"];
            }

            if (
              addOrganizationResult["success"] &&
              addOrganizationResult["data"].ResponseCode == 200
            ) {
              this.organizationList =
                addOrganizationResult["data"].ResponseData.data;
              this.lastOrgId = addOrganizationResult["data"].ResponseData.orgId;
              this.Project.organization =
                addOrganizationResult["data"].ResponseData.orgId;
              let getOwner = {
                orgId: this.lastOrgId,
                role: 0,
              };
              this.commonService
                .GetOwnerByOrganizationList(getOwner)
                .subscribe((getOwnerbyOrganizationResult) => {
                  this.loading = false;
                  if (!getOwnerbyOrganizationResult["success"]) {
                    this.message = getOwnerbyOrganizationResult["error"];
                  }
                  if (
                    getOwnerbyOrganizationResult["success"] &&
                    getOwnerbyOrganizationResult["data"].ResponseCode == 200
                  ) {
                    this.loading = false;
                    this.onKeyPress();
                    this.ownerList =
                      getOwnerbyOrganizationResult["data"].ResponseData;
                    localStorage.removeItem("pType");
                    localStorage.removeItem("gType");
                    this.growlerService.success(
                      "Successfully added organization."
                    );
                    this.growlerService.clear();
                    this.ownerArray = this.ownerList;
                    this.searchUsers = this.ownerList;

                    if (this.ownerList) {
                      this.Project.projectOwner =
                        this.ownerList[this.ownerArray.length - 1]["id"];
                    }
                  } else {
                    this.message =
                      getOwnerbyOrganizationResult["data"].ResponseMessage;
                    this.growlerService.error(this.message);
                    this.growlerService.clear();
                  }
                });
            } else {
              this.message = addOrganizationResult["data"].ResponseMessage;
              this.growlerService.error(this.message);
              this.growlerService.clear();
            }
          });
      } else {
        this.loading = false;
        this.growlerService.error(
          "Please add atleast one user in this organization."
        );
        this.growlerService.clear();
      }
    }
  }

  getorgNameText(orgTxt: any) {
    // console.log(orgTxt);
  }
  getOwnerOnUserLogin(orgId, ownerId) {
    let selectedOrgId = orgId;
    let getOwner = {
      orgId: selectedOrgId,
      role: 0,
    };
    this.loading = true;
    this.commonService
      .GetOwnerByOrganizationList(getOwner)
      .subscribe((getownerbyOrganizationResult) => {
        this.loading = false;
        if (!getownerbyOrganizationResult["success"]) {
          this.message = getownerbyOrganizationResult["error"];
        }
        if (
          getownerbyOrganizationResult["success"] &&
          getownerbyOrganizationResult["data"].ResponseCode == 200
        ) {
          this.ownerList = getownerbyOrganizationResult["data"].ResponseData;

          if (orgId == ownerId) {
            this.saveOwnergId = this.ownerList[1].id;
          } else {
            this.saveOwnergId = parseInt(localStorage.getItem("userId"));
          }

          this.searchUsers = this.ownerList;
        } else {
          this.message = getownerbyOrganizationResult["data"].ResponseMessage;
        }
      });
  }

  changeOrganization(org) {
    this.isUpdate = true;
    let selectedOrgId = org.value;
    let getOwner = {
      orgId: selectedOrgId,
      role: 0,
    };
    this.loading = true;
    this.commonService
      .GetOwnerByOrganizationList(getOwner)
      .subscribe((getownerbyOrganizationResult) => {
        this.loading = false;
        if (!getownerbyOrganizationResult["success"]) {
          this.message = getownerbyOrganizationResult["error"];
        }
        if (
          getownerbyOrganizationResult["success"] &&
          getownerbyOrganizationResult["data"].ResponseCode == 200
        ) {
          this.ownerList = getownerbyOrganizationResult["data"].ResponseData;

          this.saveOwnergId = this.ownerList[1].id;
          this.searchUsers = this.ownerList;
        } else {
          this.message = getownerbyOrganizationResult["data"].ResponseMessage;
        }
      });
  }

  confirm() {
    //this.IsmodelShow = true;
    // setTimeout(() => {
    this.router.navigateByUrl("/dashboard");
    // }, 1000);
  }

  assignProjectToUser(userstatus, userId) {
    let data = {};
    let projectIdprojectuser;
    if (this.activeProjectId) {
      projectIdprojectuser = this.activeProjectId;
    } else {
      projectIdprojectuser = this.Project.projectId;
    }

    if (userstatus == 0) {
      data = {
        status: 1,
        userId: userId,
        projectId: projectIdprojectuser,
      };
    } else {
      data = {
        status: 0,
        userId: userId,
        projectId: projectIdprojectuser,
      };
    }

    this.loading = true;
    this.commonService
      .UpdateProjectUserStatus(data)
      .subscribe((UserStatusResult) => {
        this.loading = false;
        if (!UserStatusResult["success"]) {
          this.message = UserStatusResult["error"];
        }
        if (
          UserStatusResult["success"] &&
          UserStatusResult["data"].ResponseCode == 200
        ) {
          this.growlerService.success(
            "Successfully updated user status in project."
          );
          this.growlerService.clear();
        } else {
          this.message = UserStatusResult["data"].ResponseMessage;
        }
      });
  }
  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }

        if (this.isLoggedIn == "true") {
          setTimeout(() => {
            this.getOrg();
          }, 500);
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;

          console.log("this.globalRoles++++", this.globalRoles);

          //localStorage.setItem("globalRoles", this.globalRoles);
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => { }
    );
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  changeOwner() {
    if (this.activeProjectId) {
      this.isUpdate = true;
      var projType =
        this.Project.projectType == 1 ? "sop_change_owner" : "sfr_change_owner";

      if (this.checkPermission(projType, this.permission)) {
        $("#projectOwner").val(this.tempOwner);
        alert("You do not have permissions to update projects owner");

        return false;
      }
    }
  }
  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;

          console.log("this.permission++++++", this.permission);

          //localStorage.setItem("globalRoles", this.globalRoles);
          if (this.checkPermission("sfr_update_period", this.permission)) {
            this.showCalander = true;
          } else {
            this.showCalander = false;
          }
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => { }
    );
  }
  onKeyPress() {
    this.isUpdate = true;
  }
  editPage(is_group, project_type) {
    var projType = "global_edit_proj";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    localStorage.setItem("editProjectId", this.activeProjectId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-create");
    } else {
      this.router.navigateByUrl("/project-sfr");
    }
  }
  viewPage(is_group, project_type) {
    var projType = project_type == 1 ? "sop_view_project" : "sfr_view_project";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    localStorage.setItem("editProjectId", this.activeProjectId);
    localStorage.setItem("pType", project_type);
    localStorage.setItem("gType", is_group);
    if (project_type == 1) {
      this.router.navigateByUrl("/sop-view");
    } else {
      this.router.navigateByUrl("/feedback-sfr");
    }
  }
  checkAll(event) {
    if (event.target.checked) {
      this.selectedUserList = [];
      this.isStateChecked = true;
      let userList = this.changeObjToArray(this.projectUserList);
      userList.forEach((item, index) => {
        this.selectedUserList.push(item.email);
        /* {
          id: item.id,
          email: item.email,
        } */
      });
    } else {
      this.selectedUserList = [];
      this.isStateChecked = false;
    }
    console.log(this.selectedUserList);
  }
  isUserChecked(pinboardId) {
    if (
      this.selectedUserList &&
      this.selectedUserList.indexOf(pinboardId) !== -1
    ) {
      return true;
    } else {
      return false;
    }
  }
  singleUserCheckUncheck(event, user) {
    if (event.target.checked) {
      this.selectedUserList.push(user.email);
    } else {
      this.selectedUserList = this.selectedUserList.filter(
        (e) => e !== user.email
      );
    }
    if (this.selectedUserList.length == 0) {
      this.isStateChecked = false;
    }
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  sendProjectEmail(org = null) {
    if (this.selectedUserList.length == 0) {
      this.growlerService.error("Select user to send email");
      this.growlerService.clear();
      return false;
    }
    let data = {
      user: this.selectedUserList,
      projectId: this.activeProjectId,
      projectType: this.pType,
      projectName: localStorage.getItem("projectName"),
      username: localStorage.getItem("username"),
    };
    this.loading = true;
    this.commonService
      .apiCall(data, "sendProjectEmail")
      .subscribe((emailResult) => {
        this.loading = false;
        if (!emailResult["success"]) {
          this.message = emailResult["error"];
        }
        if (emailResult["success"] && emailResult["data"].ResponseCode == 200) {
          this.selectedUserList = [];
          this.isStateChecked = false;
          this.growlerService.success("Email has been sent successfully");
          this.growlerService.clear();
          setTimeout(() => {
            this.ngOnInit();
          }, 5000);
        } else {
          this.message = emailResult["data"].ResponseMessage;
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
      });
  }
  dateFormates(date) {
    var formatted_date = "";
    if (date) {
      var formatDateComponent = function (dateComponent) {
        return (dateComponent < 10 ? "0" : "") + dateComponent;
      };
      let current_datetime = new Date(date);
      formatted_date =
        formatDateComponent(current_datetime.getDate()) +
        "-" +
        formatDateComponent(current_datetime.getMonth() + 1) +
        "-" +
        current_datetime.getFullYear() +
        "  " +
        formatDateComponent(current_datetime.getHours()) +
        ":" +
        formatDateComponent(current_datetime.getMinutes()) +
        ":" +
        formatDateComponent(current_datetime.getSeconds());
      return formatted_date;
    }
  }
  addusermodelpopup() {

    
    if (this.pType == 1) {
      if (
        (this.permission != null &&
          this.permission.sop_add_participants == true) ||
        (this.permission == null &&
          this.globalRoles.sop_add_participants == true)
      ) {
        document.getElementById("btnGroupAddonhidden").click();
      } else {
        alert("You do not have permissions to add participants.");
      }
    } else {
      if (
        (this.permission != null &&
          this.permission.sfr_add_participants == true) ||
        (this.permission == null &&
          this.globalRoles.sfr_add_participants == true)
      ) {
        document.getElementById("btnGroupAddonhidden").click();
      } else {
        alert("You do not have permissions to add participants.");
      }
    }
    console.log(this.permission);
  }
}
