<app-growler>
</app-growler>
<app-header></app-header>

<div class="loaderOuter" *ngIf="loading">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>

<div class="wrapper">
    <app-nav-bar [newLogo]='logoImg'></app-nav-bar>
    <div class="inner-container headerTopPaddipad">

        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Settings <span *ngIf="htmlOptions.viewPermission">: Permission for "{{htmlOptions.roleName}}"</span>
                    </h3>
                    <button *ngIf="htmlOptions.viewPermission" class="btn btn-primary themeBtnBlue mr-2 " (click)="closePermission()">Back</button>
                    <button *ngIf="htmlOptions.viewPermission" class="btn btn-primary themeBtnBlue" (click)="assignPermissionToRole(htmlOptions.roleId)">Save</button>
                </div>
            </div>
        </div>


        <ngb-tabset class="settingTabsOuter" [destroyOnHide]="false">
            <ngb-tab>
                <ng-template ngbTabTitle>Logo</ng-template>

                <ng-template ngbTabContent>


                    <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
                        <div class="my-profileView-left">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <label class="mb-3"> <strong>Update Logo</strong></label>
                                </div>
                                <div class="col-md-6 col-lg-5 col-xl-4 text-center">
                                    <div class="profileimgEdit">

                                        <label for="upProPic" class="text-center">
                                        <img *ngIf="logoImg" [src]="logoImg" width="300" />
                                        <img *ngIf="!logoImg" src="assets/images/dummy-img.png" width="300" />
                    <br>
                </label>
                                        <input type="file" id="upProPic" class="form-control my-3" #logo="ngModel" [(ngModel)]="htmlOptions.logo" (change)="fileEvent($event, 'logo')" name="logo" accept="image/*">
                                    </div>
                                    <a class="btn btn-light  mx-2 post-cancel-btn btnInLIneRight" routerLink='/dashboard'>Cancel</a>
                                    <button class="btn btn-primary mx-2 btn-Post-save btnInLIneRight" type="submit">Update</button>
                                </div>


                            </div>
                        </div>



                    </form>
                    <!-- <div *ngIf="validTrue" class="alert alert-success mt-2">
                        <strong>{{message}}</strong>
                    </div> -->
                    <div *ngIf="notValid" class="alert alert-danger mt-2">
                        <strong>{{message}}.</strong>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle>Users</ng-template>
                <ng-template ngbTabContent>
                    <div class="row justify-content-center mt-3">
                        <div class="col-md-4">
                            <div class="dropdown searchOrgInput">
                                <a *ngIf="!isSearched" href="javaScript:void(0)" class="crossIconOuter" (click)="getAllUserOnSearchButton(); hideDropdown();">
                                    <i class="fa fa-search" aria-hidden="true"></i>
                                </a>
                                <a *ngIf="isSearched" href="javaScript:void(0)" class="crossIconOuter" (click)="resetAllUserOnSearchButton();">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </a>
                                <input id="searchKey" (blur)="getAllUserOnSearch($event)" (keyup.enter)="getAllUserOnSearch($event)" class="form-control dropdown-toggle" placeholder="Search for an organisation">
                                <a href="javascript:void(0)" id="orgSearchBoxClick" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                <a href="javascript:void(0)" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="searchUserList">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody *ngIf="userOrgData.length > 0">
                                                <ng-container *ngFor="let user of userOrgData | keyvalue">
                                                    <tr *ngIf="changeObjToArrayForOrg(user.value, user.key)">
                                                        <td>
                                                            {{changeObjToArrayForOrg(user.value, user.key)}}
                                                        </td>
                                                        <td>
                                                            <a (click)="getAllUserBykeyWord(userData.userId)" href="javascript:void(0);" *ngFor="let userData of changeObjToArray(user.value)">
                                                                <strong>{{userData.user_name}}</strong>
                                                                <span> {{userData.title}} </span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            <tbody *ngIf="userOrgData.length == 0">
                                                <tr>
                                                    <td colspan="2">
                                                        <a href="javascript:void(0);">
                                                            <strong>No Records Found</strong>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="table-responsive project_settings-table">
                                <table class="table ">
                                    <thead>
                                        <!-- <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th> -->
                                        <th></th>
                                        <th>User Name</th>
                                        <th>User Email</th>
                                        <th class="text-center">Active?</th>
                                        <th class="text-center max-width400" width="500">Global Roles</th>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let user of userData; let i = index">
                                            <tr *ngIf="user.orgnization">
                                                <td><input type="checkbox" value="{{user.id}}" [(ngModel)]="userData[i].checked"></td>
                                                <td>
                                                    <p (click)="userDetails(user.userId)" class="mb-0 cursor-pointer">{{user.user_name}}</p>
                                                    <span>{{user.orgnization}} | {{user.title}}</span>
                                                </td>
                                                <td>
                                                    <p  class="mb-0">{{user.email}}</p>
                                                </td>
                                                <td class="text-center">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck{{user.userId}}" (click)="updateOrgStatus(user.userId,user.is_active)" [checked]='user.is_active'>
                                                        <label class="custom-control-label" for="customCheck{{user.userId}}"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-select [clearable]="false" class="max-width400" [multiple]="true" [(ngModel)]="user.roles" (add)="AddUserRole(user.userId, $event)" (remove)="removeUserRole(user.userId, $event)">
                                                        <ng-option *ngFor="let roles of userRoles" [value]="roles.id">{{roles.role}}</ng-option>
                                                    </ng-select>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="text-left">
                                    <button class="btn btn-primary" [disabled]="isAllCheckBoxNotChecked()" (click)="deleteUsers()">Delete Selected Users</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle>Roles</ng-template>
                <ng-template ngbTabContent>
                    <div class="row mt-4" *ngIf="!htmlOptions.viewPermission">
                        <div class="col-md-12">
                            <div class="table-responsive ">
                                <table class="strategic_priority-Table widthFull">
                                    <thead>
                                        <th style="max-width: 10px; width: 10px;"></th>
                                        <th style="max-width: 200px; width: 200px;" class="text-left">Role Name</th>
                                        <th style="max-width: 150px; width: 150px;" class="text-left">Edit Permission</th>
                                        <th class="text-left max-width400">Notes</th>
                                    </thead>
                                    <tbody class="strategic_priority-tbody sortable">
                                        <tr *ngFor="let role of userRoles">
                                            <td style="max-width: 10px; width: 10px;">
                                                <input type="hidden" class="questionId" value="{{role.id}}">
                                            </td>
                                            <td class=" brleftBlue">
                                                <p id="que1{{role.id}}" class="mb-0" (click)="OpenCloseDiv(role.id, role.role)">
                                                    {{role.role}}</p>
                                                <input class="hideBlock" id="que2{{role.id}}" type="text" id="questionBox" (blur)="updateQuestion(role.id, role.description, $event.target.value)" placeholder="+Question">
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" (click)="getPermission(role.id, role.role)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                            </td>
                                            <td>
                                                <p *ngIf="role.description" id="ans1{{role.id}}" class="mb-0" (click)="OpenCloseDivAns(role.id, role.description)">{{role.description}}</p>
                                                <input class="hideBlock" id="ans2{{role.id}}" type="text" (blur)="UpdateQuestionNotes(role.id, role.role, $event.target.value)" (keyup.enter)="UpdateQuestionNotes(role.id, role.role, $event.target.value)" placeholder="+Question">

                                                <input *ngIf="!role.description" type="text" (blur)="addQuestionNotes(role.id, role.role, $event.target.value)" (keyup.enter)="addQuestionNotes(role.id, role.role, $event.target.value)" placeholder="Enter descrption notes here">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td class=" brleftBlue">
                                                <input type="text" id="questionBox" (blur)="addQuestion($event.target.value)" (keyup.enter)="addQuestion($event.target.value)" placeholder="+Add new role">
                                                <div *ngIf="notValid" class="alert alert-danger mt-2">
                                                    <strong>{{message}}.</strong>
                                                </div>
                                            </td>
                                            <td></td>
                                            <td>
                                                <input type="text" id="answerBox" (blur)="addQuestionNotesSecond($event.target.value)" (keyup.enter)="addQuestionNotesSecond($event.target.value)" placeholder="Enter descrption notes here">
                                                <!-- <div *ngIf="notValid1" class="alert alert-danger mt-2">
                                                    <strong>{{message1}}.</strong>
                                                </div> -->
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4" *ngIf="htmlOptions.viewPermission">
                        <div class="col-md-12">
                            <div class="table-responsive project_settings-table">
                                <table class="table">
                                    <!--  <thead>
                                        <th class="text-left">Role Name</th>
                                    </thead> -->
                                    <tbody class="strategic_priority-tbody sortable">
                                        <p>Global Role</p>
                                        <ng-container *ngFor="let permission of permissionData">
                                            <tr *ngIf="permission.type ==1">
                                                <td class="width45per brleftBlue">
                                                    <p id="que1{{permission.id}}" class="mb-0">
                                                        {{permission.title}}</p>
                                                </td>
                                                <td class="text-right">
                                                    <div class="switcherOuter-body mt-2">
                                                        <div class="switchToggle d-inline-block ml-2">
                                                            <input [(ngModel)]="savePermissionData[permission.key]" type="checkbox" id="switch{{permission.id}}">
                                                            <label for="switch{{permission.id}}"></label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <p>Strategic Operating Plan (Project Level)</p>
                                        <ng-container *ngFor="let permission of permissionData">
                                            <tr *ngIf="permission.type ==2">
                                                <td class="width45per brleftBlue">
                                                    <p id="que1{{permission.id}}" class="mb-0">
                                                        {{permission.title}}</p>
                                                </td>
                                                <td class="text-right">
                                                    <div class="switcherOuter-body mt-2">
                                                        <div class="switchToggle d-inline-block ml-2">
                                                            <input type="checkbox" [(ngModel)]="savePermissionData[permission.key]" id="switch{{permission.id}}">
                                                            <label for="switch{{permission.id}}"></label>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <p>Stakeholder Feedback Report (Project Level)</p>
                                        <ng-container *ngFor="let permission of permissionData">
                                            <tr *ngIf="permission.type ==3">
                                                <td class="width45per brleftBlue">
                                                    <p id="que1{{permission.id}}" class="mb-0">
                                                        {{permission.title}}</p>

                                                </td>
                                                <td class="text-right">
                                                    <div class="switcherOuter-body mt-2">

                                                        <div class="switchToggle d-inline-block ml-2">
                                                            <input type="checkbox" [(ngModel)]="savePermissionData[permission.key]" id="switch{{permission.id}}">
                                                            <label for="switch{{permission.id}}"></label>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
            <ngb-tab>
                <ng-template ngbTabTitle>Audit Logs</ng-template>
                <ng-template ngbTabContent>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="table-responsive ">
                                <table class="strategic_priority-Table widthFull lasttdbrLeftNormal">
                                    <thead>
                                        <th style="max-width: 200px; width: 200px;" class="text-left"></th>
                                    </thead>
                                    <tbody class="strategic_priority-tbody sortable">
                                        <tr *ngFor="let log of logs">
                                            <td class="brleftBlue">
                                                <p class="mb-0">
                                                    {{log.message}}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="text-right mt-3">

                                <ul class="ngx-pagination">
                                    <li [ngClass]="[currentPage==1 ? 'pagination-previous disabled' : '']">
                                        <a class="" href="javascript:void(0)" (click)="callPagination(currentPage-1)">Previous</a>
                                    </li>

                                    <ng-container *ngFor="let number of totalPageArr">
                                        <li [ngClass]="[number+1==currentPage ? 'current' : '']">
                                            <a class="" href="javascript:void(0)" (click)="callPagination(number+1)">{{number+1}}</a>
                                        </li>
                                    </ng-container>
                                    <li *ngIf="totalPage>10 && currentPage+1<totalPage">...</li>
                                    <li *ngIf="currentPage+1<totalPage" class="">
                                        <a class="" href="javascript:void(0)" (click)="callPagination(totalPage)">{{totalPage}}</a>
                                    </li>
                                    <li [ngClass]="[currentPage==totalPage ? 'pagination-next disabled' : '']">
                                        <a class="" href="javascript:void(0)" (click)="callPagination(currentPage+1)">Next</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </ng-template>
            </ngb-tab>

        </ngb-tabset>

    </div>




</div>

<a href="javascript:void(0)" data-toggle="modal" data-target="#userClick" id="userModal"></a>
<div data-backdrop="false" class="modal fade" id="userClick" tabindex="-1" role="dialog" aria-labelledby="userClick" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title m-0">Update User Details</h3>
                <button type="button" id="userClickHide" class="close" data-dismiss="modal" aria-label="Close" (click)="closeUserDetails()">
                        <span aria-hidden="true">&times;</span>
                    </button>
            </div>
            <div class="modal-body py-4">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="my-profileView-left">
                            <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmitData()" novalidate>
                                <div class="media mb-3">
                                    <!-- <div class="profileimgEdit">
                                        <label for="editProfilePic">
                                            <img *ngIf="Profile.profile_image" [src]="Profile.profile_image" width="300" />
                                            <img *ngIf="!Profile.profile_image" src="assets/images/dummy-img.png" width="300" />

                                        </label>
                                        <label for="editProfilePic"><i for="editProfilePic" class="fa fa-upload"></i> Upload
                                            Photo</label>
                                        <input type="file" class="" id="editProfilePic" (change)="fileEvent($event, 'profile_image')" name="profile_image" accept="image/*">
                                    </div> -->
                                    <div class="media-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>NAME<span class="req">*</span></label>
                                                            <input type="text" trim="blur" maxlength="80" class="form-control" placeholder="Enter Name" name="user_name" [(ngModel)]="Profile.user_name" #user_name="ngModel" required>
                                                            <div *ngIf="f.submitted && !user_name.valid" class="invalid-feedback-div">
                                                                Name field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>JOB TITLE<span class="req">*</span></label>
                                                            <input type="text" trim="blur" maxlength="80" class="form-control form-control-line" placeholder="Enter Title" name="title" [(ngModel)]="Profile.title" #title="ngModel" required>
                                                            <div *ngIf="f.submitted && !title.valid" class="invalid-feedback-div">Title field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Email Address<span class="req">*</span></label>
                                                            <input [readonly]='true' type="text" trim="blur" maxlength="80" class="form-control form-control-line" placeholder="Enter Email" name="email" [(ngModel)]="Profile.email" #email="ngModel" required>
                                                            <div *ngIf="f.submitted && !email.valid" class="invalid-feedback-div">Email field is required
                                                            </div>
                                                        </div>
                                                    </div>

                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label>Organisation<span class="req">*</span></label>
                                                                <select class="form-control" name="organization_id" id="organization_id" [(ngModel)]="Profile.organization_id"
                                                                (ngModelChange)="onOrganizationChange($event)"  #organization_id="ngModel" required >
                                                                    <option *ngFor="let key of organizationList" [value]="key.id">
                                                                        {{ key.user_name }}</option>
                                                                </select>
                                                                <div *ngIf="f.submitted && !organization_id.valid" class="invalid-feedback-div">Organisation field is required
                                                                </div>
                                                            </div>
                                                        </div>
    
                                                       
                                                  
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>OLD PASSWORD </label>
                                                            <input type="password" class="form-control" maxlength="29" name="password" [(ngModel)]="Profile.password" #password="ngModel">
                                                            <div *ngIf="!Profile.password && Profile.new_password" class="invalid-feedback-div">Old Password field is required</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>NEW PASSWORD</label>
                                                            <input type="password" class="form-control" maxlength="29" name="new_password" [(ngModel)]="Profile.new_password" #new_password="ngModel" pattern="^[0-9,a-z,A-Z,@,#,$,^,!,%,&,*]{6,30}$" [required]="Profile.password">
                                                            <!--  <div *ngIf="f.submitted && !new_password.valid" class="invalid-feedback-div">New Password field is required</div> -->
                                                            <div *ngIf="new_password.errors && new_password.errors.pattern" class="invalid-feedback-div">Password must contain at least 6 characters
                                                            </div>
                                                            <div *ngIf="f.submitted && Profile.password && !Profile.new_password && !new_password.errors.pattern" class="invalid-feedback-div">New Password field is required</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>RE-ENTER NEW PASSWORD</label>
                                                            <input type="password" class="form-control" maxlength="80" name="cpassword" [(ngModel)]="htmlOptions.cpassword" #cpassword="ngModel">
                                                            <div *ngIf="!htmlOptions.cpassword && Profile.new_password" class="invalid-feedback-div">Re-enter Password field is required</div>
                                                            <div *ngIf="(Profile.new_password && htmlOptions.cpassword && htmlOptions.cpassword != Profile.new_password)" class="invalid-feedback-div">Re-enter password doesn't match with password
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                          <label>Secondary Organization </label>
                                                          <ng-select 
                                                            [clearable]="false" 
                                                            class="max-width400" 
                                                            [multiple]="true" 
                                                            [(ngModel)]="Profile.secondary_org_id" 
                                                            (add)="addOrg($event)" 
                                                            (remove)="removeOrg($event)" 
                                                            name="secondaryorgList" 
                                                            >
                                                            <ng-option *ngFor="let org of seconedary_orgList" [value]="org.id">
                                                              {{ org.user_name }}
                                                            </ng-option>
                                                          </ng-select>
                                                          <div *ngIf="secondaryorgList.length >= 5" class="text-danger">
                                                            Maximum of 4 organizations can be added.
                                                          </div>
                                                        </div>
                                                      </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>BIO</label>
                                                    <textarea name="bio" rows="2" class="form-control" [(ngModel)]="Profile.bio"></textarea>
                                                </div>
                                            </div>

                                            <div class="col-md-12 text-right">
                                                <button class="btn btn-light ml-2" data-dismiss="modal"> Cancel </button>
                                                <button class="btn btn-primary mx-2 btn-Post-save btnInLIneRight"> Save </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
            </div>
            <!--  <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div> -->
        </div>
    </div>
</div>