import { Component, OnInit, Pipe, ViewChild, ElementRef  } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { environment } from "../../../environments/environment";
/* import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui"; */
 import * as jspdf from "jspdf";
 import html2canvas from "html2canvas";
 declare var $: any;
 import * as CryptoJS from 'crypto-js';
 import { ToastrService } from 'ngx-toastr';

 @Component({
  selector: 'app-sop-feedback-view',
  templateUrl: './sop-feedback-view.component.html',
  styleUrls: ['./sop-feedback-view.component.scss']
})
export class SopFeedbackViewComponent implements OnInit {
  public showLoder: boolean = true;
  public sidebarToggle: boolean = false;
  public userColHide: boolean = false;
  showheader:boolean = false;
  imageurl:any;
  pageSize: any;
  followup: any;
  timer:number;
  globalRoles: any = [];
  orderObj: boolean = false;
  isorderObj: boolean = true;
  permission: any = [];
  notValid: boolean = false;
  validTrue: boolean = false;
  projectData: any = [];
  ratingAndComment: any = [];
  ProjectPeriod: any = [];
  ProjectPeriodCopy: any = [];
  ExtraColumn: any = [];
  projectDetail: any = [];
  colsapnValue: any = 1;
  mergeTdRemove: number;
  mergeTdRemoveArr: any = [];
  feedback: any = {};
  htmlOptions: any = {};
  priority: any = [];
  periods: any = [];
  usersList: any=[];
  priorities: any = {};
  loading: boolean = false;
  scrollLeftSize:number = 0;
  showScrollRight:boolean = true;
  message: any;
  pType: any;
  scr:any;
  gType: any;
  loginUser:any
  activeProjectId: any;
  updetedPeriodId:number;
  periodPosition:any;
 logoUrl:any;
 today:any;
 projectOwnerData:any = [];
 totalboxinview:number = 3;
 projectUserList : any = [];
 pId_global: any = 0; 
 taskId_global: any = 0; 
 InitativeId_global: any = ""; 
 follow_up_date : any;
 secretKey = 'Secret123456789!@#$%^&*%';
 userroles : any;


  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    private elementRef:ElementRef,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    // console.log('ngonit');
    

  
    this.route.queryParamMap
    .subscribe((params) => {
        if('first' in params['params']){
          this.orderObj = true;

        }
      }
    );

    this.route.queryParams.subscribe(params => {
      const projectId = params['projectId'];

      console.log("projectId++++",projectId);
      
      if (projectId) {
        // Decrypt the encryptedId
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(projectId), this.secretKey);
        this.activeProjectId = bytes.toString(CryptoJS.enc.Utf8);

        console.log("this.activeProjectId++++",this.activeProjectId)
      }
    });

    setTimeout(() => {
      this.setSideBar();
      if(this.orderObj && this.isorderObj){
        this.isorderObj = false;
        this.growlerService.success("Go to project setting to change the project name.");
        setTimeout(() => {
          this.growlerService.clear();
        }, 2000);
      }
    }, 100);

    //let myDate = new Date();
    this.imageurl = environment.BASE_URL;
    this.today = this.dateFormates();
    this.logoUrl = localStorage.getItem("logo");
    this.priorities.pageSize='';
    this.priorities.followup='false';
    this.priorities.followup_date=null;
    this.priorities.content = null;
    this.priority = [];
    this.pType = localStorage.getItem("pType");
    this.gType = localStorage.getItem("gType");
    this.loginUser = localStorage.getItem("userId");
    this.priorities.project_id = this.activeProjectId;
    this.getProjectData(this.activeProjectId);
    this.getOwnerProjectData(this.activeProjectId);
    this.editProjectSetting(this.activeProjectId);
    this.setHtmlOptions();
    this.customeSlider(1);
    this.userroles = parseInt(localStorage.getItem("roles"));
    this.getPermissionByRole(this.userroles);
    this.getUserPermissionOnProject(this.activeProjectId);
    $("body").on("click", ".dropdown-menu", function (e) {
      $(this).parent().is(".show") && e.stopPropagation();
    });
    var element = document.getElementById("bodyMain");
    var element2 = document.getElementById("fullScreenBtn");
    element.classList.toggle("fullScreenOn");
    // element2.classList.toggle("active");
    $("#fullScreenBtn").addClass("active");
    $("#fullScreenBtn").attr("hidden",true);
    // document.getElementById('fullScreenBtn').hidden = true;
    // document.getElementById('fullScreenBtnExit').hidden = false;
    $("#fullScreenBtnExit").attr("hidden",false);


   

    setTimeout(() => {
      var element = document.getElementById("bodyMain");
      var element2 = document.getElementById("fullScreenBtn");
      $("#fullScreenBtn").removeClass("active");
      element.classList.toggle("fullScreenOn");
      //element2.classList.toggle("active");
      document.getElementById('fullScreenBtn').hidden = false;
      document.getElementById('fullScreenBtnExit').hidden = true;
    }, 2000);

  }

  SendInvite(){
    console.log("prioirt",JSON.stringify(this.priorities));

    this.commonService
    .inviteFeedbackUser(this.priorities)
    .subscribe((inviteFeedbackUserResult) => {

      console.log("inviteFeedbackUserResult++++",inviteFeedbackUserResult)
      
      this.loading = false;

      if (!inviteFeedbackUserResult["success"]) {
        this.message = inviteFeedbackUserResult["error"];
      }

      if (
        inviteFeedbackUserResult["success"] &&
        inviteFeedbackUserResult["data"].ResponseCode == 200
      ) {
       
     
    
      
      } else {
        this.message =
        inviteFeedbackUserResult["data"].ResponseMessage;
        this.growlerService.error(this.message);
        this.growlerService.clear();
      }
    });
  }

  goToProjectSetting(editProjId, projectName, project_type, project) {
    this.fullScreenExit();
    var projectKey =
      project_type == 1 ? "sop_change_settings" : "sfr_change_settings";

    if (this.checkPermission(projectKey, this.permission)) {
      alert("You do not have permissions to change project setting.");
      return false;
    }
    this.router.navigateByUrl("/project-settings");
  }
  editPage(is_group, project_type) {
    this.fullScreenExit();
    var projType = "global_edit_proj";
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permission to edit project");
      return false;
    }
    this.router.navigateByUrl("/sop-create");
  }

  
  onDateChange(event: Event): void {
    // Update historyslotDate when the date input changes
    const inputElement = event.target as HTMLInputElement;
    this.follow_up_date = inputElement.value;
   
    // const currentDate = new Date();
    // let cmpDate = new Date(this.follow_up_date);

    // currentDate.setHours(0, 0, 0, 0);
    // cmpDate.setHours(0, 0, 0, 0);
    
  }

  getPermissionByRole(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  checkPermission(permissionKey, permissions) {
    if (
      (permissions != null &&
        (permissions[permissionKey] === undefined ||
          !permissions[permissionKey])) ||
      (permissions === null && this.globalRoles[permissionKey] === undefined)
    ) {
      return true;
    }
  }
  setHtmlOptions() {
    this.htmlOptions = {
      individualMode: true,
      averageMode: false,
      medianMode: false,
      adjustedMode: false,
      download: true,
    };
  }
  customeSlider(rating) {
    $(function () {
      var handle = $(".custom-handle");
      $(".slider").slider({
        orientation: "horizantal",
        range: "min",
        value: rating,
        step: 0.5,
        min: 1.0,
        max: 10.0,
        animate: "fast",
        create: function () {
          handle.text($(this).slider("value"));
          handle.addClass(
            "rating" + Math.round($(this).slider("value")) + "bg"
          );
        },
        slide: function (event, ui) {
          handle.text(ui.value);

          // remove all other classes start - 25-07-2022 (some classes was not removed from slider so color change issue)
          // for (let n = 1; n < 11; ++n) {
          //   if(Math.round(ui.value) != n)
          //   handle.removeClass("rating" + n + "bg");
          // }
          handle.removeClass (function (index, className) {
              // console.log("className before remove ** ", className); 
              return (className.match (/rating(\w+)/g) || []).join(' '); // (className.match (/\bcolor-\S+/g) || []).join(' ');
          });
          // remove all other classes end - 25-07-2022

          // console.log("Math.round(ui.value) **** ", Math.round(ui.value)); 
          handle.addClass("rating" + Math.round(ui.value) + "bg");
          // handle.removeClass("rating" + (Math.round(ui.value) + 1) + "bg");  // commented on 25-07-2022

          var attrId = $(this)
            .closest(".dropdown-menu.p-1")
            .find('input[name="feedbackRange"]')
            .attr("id");
          $("#" + attrId).val(ui.value);
        },
      });
    });
  }
  addRating(InitativeId:any,taskId: any, pId: any, commntes: any) {
    
    var projType = "sop_provide_rating";
    //$(".pcontainer").removeClass("maxwidthbox");
    if (this.checkPermission(projType, this.permission)) {
      alert("You do not have permissions to rate the tasks");
      return false;
    }
    let projectId = this.activeProjectId;

    let rating = $("#range" + taskId + pId).val();
    pId = $("#rangeperiod" + taskId + pId).val();
    if (rating == 0) {
      rating = 1;
    }
    this.addRatingAndCommentTask(InitativeId,taskId, rating, pId, commntes, projectId);
  }
  addRatingAndCommentTask(InitativeId,taskId, rating, pId, commntes, projectId) {
    
    let data = {
      taskId: taskId,
      rating: rating,
      periodId: pId,
      comment: commntes,
      projectId: projectId,
      self: "self"
    };

    this.loading = true;
    //call login api here
    this.commonService.addRatingAndCommentTask(data).subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //location.reload();+
          this.ngOnInit();
          var width = $("#widgetsContent").width();
          console.log("width"+width);
          this.totalboxinview = Math.floor(parseInt(width)/190)-1;
          console.log("InitativeId ******** ", InitativeId); 

          this.pId_global = pId; 
          this.InitativeId_global = InitativeId; 
          /*  // commented on 28-06-2022 as it fails when api take more time 
          setTimeout(() => {
            document.getElementById(InitativeId).scrollIntoView();
            this.periodPosition = $("#"+pId).parent().parent().children().index($("#"+pId).parent());
            
            if(this.periodPosition>this.totalboxinview){
            var itrationcount = this.periodPosition-this.totalboxinview;
            
            var i;
              for (i = 0; i < itrationcount; i++) {
                this.scrollRight();
              }
            }           
            
          }, 4500);

          */ 
          
          this.feedback.text = "";
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  blankBox() {

    this.feedback.text = "";
    $(".custom-handle").text(1);
    var handle = $(".custom-handle");
    handle.text();
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }
    // console.log(" blankBox **** "); 
    this.customeSlider(1);

    // setTimeout(() => {
    //   $(".pcontainer").removeClass("maxwidthbox");
    // }, 3000);
  }
  blankBoxRating(rating,comment) {
    this.feedback.text = comment;
    $(".custom-handle").text(rating);
    var handle = $(".custom-handle");
    handle.text();
    // below code commented on - 25-07-2022
    for (let n = 2; n < 11; ++n) {
      handle.removeClass("rating" + n + "bg");
    }

    // console.log("blankBoxRating *** ", rating); 
    handle.addClass("rating" + rating + "bg");
    this.customeSlider(rating);

    // setTimeout(() => {
    //   $(".pcontainer").removeClass("maxwidthbox");
    // }, 3000);
  }

  ClickedOut(event) {
  }
  getProjectData(activeProjectId) {
    this.priority = [];
    this.periods = [];
    let data = {
      projectId: activeProjectId, //58
    };
    this.loading = true;
    //call login api here
    this.commonService.GetFeedbackProjectData(data).subscribe(
      (result) => {
        console.log("result+++",result)
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          let projectData = this.changeObjToArraySort(
            result["data"]["ResponseData"]["ProjectData"]
          );
          var self = this;
          projectData.forEach(function (item) {
            self.priority.push(item.id);
          });

          this.priorities.data = this.priority;

          console.log("this.priorities.data+++",this.priorities.data);
          //Sort
          this.customeSlider(1);
          this.ProjectPeriodCopy = this.ProjectPeriod = this.changeObjToArray(
            result["data"]["ResponseData"]["ProjectPeriod"]
          );
          this.ProjectPeriod.forEach(function (item) {
            self.periods.push(item.id);
          });

          this.priorities.periods = this.periods;
          this.ExtraColumn = this.changeObjToArray(
            result["data"]["ResponseData"]["ExtraColumn"]
          );
          this.projectData = Object.keys(projectData).map(function (
            personNamedIndex
          ) {
            let project = projectData[personNamedIndex];
            return project;
          });
          this.projectDetail = result["data"].ResponseData.Projectsetting;

          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        } else {
          
          this.router.navigateByUrl("/dashboard");
          this.toastrService.info("Feedback form submitted successfully");
          
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;

        }

        // scroll when project data load start - 28-06-2022
        if(this.pId_global || this.taskId_global || this.InitativeId_global){
          this.scrollOnDataLoad(this.pId_global, this.taskId_global, this.InitativeId_global);
          // empty all global varible
          this.pId_global  = 0; 
           this.taskId_global  = 0; 
           this.InitativeId_global = ""; 
        }

        // scroll when project data load end 


      },
      (error) => {}
    );
  }

  is_client_participant : boolean = false;

  editProjectSetting(activeProjectId) {
   
    this.usersList =[];
    var self=this;
    let projectDetail = {
      projectId: activeProjectId,
    };
    this.commonService
      .editProjectSetting(projectDetail)
      .subscribe((projectSettingResultData) => {
        this.loading = false;
        if (!projectSettingResultData["success"]) {
          this.message = projectSettingResultData["error"];
        }

        if (
          projectSettingResultData["success"] &&
          projectSettingResultData["data"].ResponseCode == 200
        ) {


       
          this.projectUserList =
            projectSettingResultData["data"].ResponseData.users;

            this.is_client_participant = this.projectUserList.find(user => user.id == this.loginUser)?.roleId == 2;


            this.projectUserList.forEach(function (item) {
              self.usersList.push(item.id);
            });
  
            this.priorities.users = this.usersList;
          

        } else {
          this.message = projectSettingResultData["data"].ResponseMessage;
        }
      });
  }



  getOwnerProjectData(activeProjectId) {
    let data = {
      projectId: activeProjectId, //58
    };
    this.commonService.apiCall(data,"getOwnerProjectData").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.projectOwnerData = result["data"].ResponseData[0];
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  changeObjToArrayForTasks(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    return project;
  }
  changeObjToArray(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });
    return project;
  }
  changeObjToArraySort(projectData) {
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      return project;
    });

    project.sort(function (a, b) {
      var keyA = a.priority,
        keyB = b.priority;
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return project;
  }

  checkMergeColumn(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId = currentId + 1;
    let manageColumn = 0;
    if (init[periodId]) {
      if (init[nextperiodId] != undefined) {
        if (init[currentId].Task.id == init[nextperiodId].Task.id) {
          this.colsapnValue = 2;
          manageColumn = 1;
        }
      }
    }
    return manageColumn;
  }
  setColspanValue(init: any, periodId: number) {
    let currentId = init[periodId].periodId;
    let nextperiodId: any;
    var colsapnValue = 1;
    if (
      $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      nextperiodId = $("#" + periodId);
      nextperiodId = $("#" + periodId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    this.colsapnValue = 1;
    this.mergeTdRemove = 0;
    this.mergeTdRemoveArr = [];
    this.mergeTdRemoveArr.push(currentId);
    if (init[periodId]) {
      while (init[nextperiodId] != undefined) {
        if (init[nextperiodId] != undefined) {
          if (init[currentId].Task.id == init[nextperiodId].Task.id) {
            colsapnValue++;

            this.mergeTdRemoveArr.push(parseInt(nextperiodId));
            if (
              $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id") != undefined
            ) {
              nextperiodId = $("#" + nextperiodId)
                .parent("th")
                .next()
                .children("p")
                .attr("id");
            } else {
              break;
            }
          } else {
            break;
          }
        } else {
          break;
        }
      }
    }
    return colsapnValue;
  }
  hideDivNextTime(inIt, pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .prev()
        .children("p")
        .attr("id");
    }
    //return
    let aTaskId: any;
    let bTaskId: any;
    if (inIt[PrePerIodId] != undefined) {
      aTaskId = inIt[pId].Task.id;
      bTaskId = inIt[PrePerIodId].Task.id;
      if (aTaskId == bTaskId) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  }
  counter(i: number) {
    if (i && i > 1) {
      return new Array(i);
    }
  }
  getNextId(pId) {
    let PrePerIodId: number;
    if (
      $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id") != undefined
    ) {
      PrePerIodId = $("#" + pId)
        .parent("th")
        .next()
        .children("p")
        .attr("id");
    }
    return PrePerIodId;
  }
  getRatingAndComment(taskId, periodId) {
    let data = {
      taskId: taskId,
      periodId: periodId,
    };

    // this.loading = true;
    //call login api here
    this.commonService.getRatingAndComment(data).subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          this.ratingAndComment = result["data"].ResponseData[0];
          console.log('myrating11',this.ratingAndComment);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
    // this.loading = false;
    this.sidebarToggle = true;
    this.userColHide = true;
  }
  getRatingAndCommentforloop($event) {
    console.log("here"+$event.target.id)
    var dataArr = $event.target.id.split("-");
    var taskId = dataArr[0]
    var periodId = dataArr[1]
    let data = {
      taskId: taskId,
      periodId: periodId,
    };

    // this.loading = true;
    //call login api here
    this.commonService.getRatingAndComment(data).subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.validTrue = true;
          setTimeout(() => {
            this.validTrue = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
          this.ratingAndComment = result["data"].ResponseData[0];
          //console.log('myrating',this.ratingAndComment);
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
    // this.loading = false;
    this.sidebarToggle = true;
    this.userColHide = true;
  }

  changeProjectType(metricType) {
    if (metricType.target.value == 1) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: true,
        medianMode: false,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 2) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: true,
        adjustedMode: false,
      };
    } else if (metricType.target.value == 3) {
      this.htmlOptions = {
        individualMode: false,
        averageMode: false,
        medianMode: false,
        adjustedMode: true,
      };
    } else {
      this.htmlOptions = {
        individualMode: true,
        averageMode: false,
        medianMode: false,
        adjustedMode: false,
      };
    }
    this.htmlOptions.download = true;
    this.customeSlider(1);
  }
  averageRating(allRatings) {
    const allRatingsArr = this.changeObjToArray(allRatings);
    const arrAvg =
      allRatingsArr.reduce((a, b) => a + b, 0) / allRatingsArr.length;
    return Math.round(arrAvg);
  }
  getRound(rating) {
    return Math.round(rating);
  }
  medianRating(allRatings) {
    const values = this.changeObjToArray(allRatings);
    const mid = Math.floor(values.length / 2),
      nums = [...values].sort((a, b) => a - b);
    const median = Math.round(
      values.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2);
    if (values && values.length < 5) {
      if(median==0){
        return 0;
      }else{
        return "N/A";
      }


    } else {
      return median;
    }
  }
  adjustedMode(allRatings) {
    const values = this.changeObjToArray(allRatings);

    if (values && values.length < 5) {
      if(Math.max(...values) > 0){
        return "N/A";
      }else{
        return 0;
      }

    } else {
      var maxValue = Math.max(...values);
      var minValue = Math.min(...values);
      const arrAvg = values.reduce((a, b) => a + b, 0);
      const adjusted = (arrAvg - maxValue - minValue) / values.length - 2;
      return Math.round(adjusted);
    }
  }
  closeCommentPopup() {
    this.sidebarToggle = false;
    this.userColHide = false;
  }

  userColHideShow() {
    this.userColHide = !this.userColHide;
  }
  addPriorities(e: any) {
    this.priority.push(e);
  }
  removePriorities(e: any) {
    console.log('remove priorities...', e.value);
    console.log('remove priorities...', this.priority);
    this.priority = this.priority.filter(item => item !== e.value);
    // this.priority.pop(e.value);
  }
  addPeriods(e: any) {
    this.periods.push(e);
    let newPeriod = this.ProjectPeriod.filter(val=>{
      return val.id == e
    })
    this.ProjectPeriodCopy.push(newPeriod[0]);
  }

  removePeriods(e: any) {
    this.periods.pop(e.value);
    this.ProjectPeriodCopy = this.ProjectPeriodCopy.filter(val=>{
      return val.id != e.value
    })
  }

  addUsers(e: any) {
    console.log('add periods...', e);
    this.usersList.push(e);
    let newPeriod = this.projectUserList.filter(val=>{
      return val.id == e
    })
  }
  removeUsers(e: any) {
    console.log('remove periods...', e);
    this.usersList.pop(e.value);
   
  }


  checkPriorityForPrint(priorityId) {
    if (this.priority) {
      this.priority.forEach(function (item) {
        if (item == priorityId) {
          return false;
        }
      });
    }
    return true;
  }
  priorityIncludes(projectId){
    if (this.priority.includes(projectId)) {
      return true;
    }
      return false
  }
  closeDiv(){
    this.ngOnInit();
    document.getElementById("closeDiv").click();
  }

  closeShareDiv(){
    this.ngOnInit();
    document.getElementById("closeShareDiv").click();
  }

  closeDivPdf(){
    $('#downloadModal').modal('hide');
    location.reload();
  }


  captureScreen1() {
    //$("#fullScreenBtn").click();
    $("body").addClass("fullScreenOn");

    $(".headerTopPaddipad").css("overflow", "visible");

    $(".widgetsContent").css("overflow", "visible");
    $(".pcontainer").addClass("maxwidth100");
    $("#closeDiv").click();

    this.htmlOptions.download = false;
    this.loading = true;

    this.loading = false;

    let pdf = new jspdf("l", "mm", this.pageSize);
    var position = 0;

    const elementToPrint = document.getElementsByTagName("body"); //The html element to become a pdf
    var self = this;
    var options = {
      pagesplit: true,
    };

    pdf.addHTML(elementToPrint, () => {
      self.htmlOptions.download = true;
      this.priority = [];
      pdf.save(this.projectDetail.title + ".pdf");
      $("body").removeClass("fullScreenOn");
      this.ngOnInit();
    });

    // // html2canvas(data).then((canvas) => {

    // });
  }
  captureScreen() {
    
    // this.loading = true;
    this.showheader = true;
    $(".headerTopPaddipad").css("overflow", "visible");
    $(".widgetsContent").css("overflow", "visible");
    //$(".pcontainer").addClass("maxwidth100");
    $(".pcontainer").removeAttr("style");
    $("#closeDiv").click();
    const elementToPrint = $("#contentToConvert").clone().find(".notinpdf").remove().end().html()
    console.log('element to print...', elementToPrint);
    setTimeout(() => {
      const hdata = $("#headerdiv").clone().end().html();
      let data = {
        method:"printpdf",
        action:"print",
        html: elementToPrint,
        pageSize: this.pageSize,
        orientation:'landscape',
        header: hdata
      };
      this.commonService.apiCallPdf(data).subscribe(
        (result) => {
          if (result["statusCode"] && result["statusCode"] == 200) {
            var fileToDownload = result["responseData"];
            var href =
              "https://d37b3e1yz0z6u8.cloudfront.net/" + fileToDownload;
            setTimeout(() => {
              var req = new XMLHttpRequest();
              req.open("GET", href, true);
              req.responseType = "blob";
              req.onload = function (event) {
                var blob = req.response;
                var link=document.createElement('a');
                link.href=window.URL.createObjectURL(blob);
                link.download="SOP_" + new Date() + ".pdf";
                link.click();
                //console.log('link',link);
                $("#downloadView").attr("href",link.href);
                //return;
              };
              
              req.send();
              var fiveMinutes = 20,
              display = document.querySelector('#time');
              this.startTimer(fiveMinutes, display);
              $('#downloadModal').modal('show');
               setTimeout(() => {
                $('#downloadModal').modal('hide');
                 location.reload();
               }, 20000);

            }, 1000);
            
          }
        },
        (error) => {}
      )
    }, 1000);
    
  }

  startTimer(duration, display) {
      let timer = duration, minutes, seconds;
      
      setInterval(function () {
          minutes = timer/60
          seconds = timer % 50;
          //console.log('timer',seconds);
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          display.textContent ='00:'+seconds;

          if (--timer < 0) {
              timer = duration;
          }
      }, 1000);
  }

  getUserPermissionOnProject(projectId) {
    var data = {
      projectId: projectId,
    };
    this.commonService.apiCall(data, "getUserPermissionOnProject").subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.permission = result["data"].ResponseData;
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  // scrollLeft() {
  //   $(".widgetsContent").animate(
  //     {
  //       scrollLeft: "-=190px",
  //     },
  //     "slow"
  //   );
  // }

  // scrollRight() {
  //   $(".widgetsContent").animate(
  //     {
  //       scrollLeft: "+=190px",
  //     },
  //     "slow"
  //   );
  // }

  scrollLeft() {
    // Determine scroll value based on screen size
    const scrollValue = window.innerWidth <= 768 ? 140 : 190; // 140px for mobile, 190px for larger screens
    
    // Perform the scroll animation
    $(".widgetsContent").animate(
      {
        scrollLeft: `-=${scrollValue}px`,
      },
      "fast"
    );
  
    const container = document.querySelector('.widgetsContent');
    this.scrollLeftSize = container.scrollLeft - scrollValue;
  
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
  
    // Adjust the logic based on the scroll value
    if (ow + container.scrollLeft >= x) {
      this.showScrollRight = false;
    } else {
      this.showScrollRight = true;
    }
  }
  

  scrollRight() {
    // Determine scroll value based on screen size
    const scrollValue = window.innerWidth <= 768 ? 140 : 190; // 140px for mobile, 190px for larger screens
    
    // Perform the scroll animation
    $(".widgetsContent").animate(
      {
        scrollLeft: `+=${scrollValue}px`,
      },
      "fast"
    );
  
    var elmnt = document.getElementById("widgetsContent");
    const container = document.querySelector('.widgetsContent');
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
  
    // Adjust the logic based on the scroll value
    if (ow + container.scrollLeft + scrollValue >= x) {
      this.showScrollRight = false;
    } else {
      this.showScrollRight = true;
    }
  
    // Update scroll left size
    this.scrollLeftSize = container.scrollLeft + scrollValue;
  }
  

  changeSize(size) {
    this.pageSize = size;
  }

  onFollowup() {
    if (this.priorities.followup === 'false') {
      // Clear the follow-up date and content if 'No' is selected
      this.priorities.followup_date = null;
      this.priorities.content = null;
    }
  }

  checkAvailavility(){
    var elmnt = document.getElementById("widgetsContent");
    var x = elmnt.scrollWidth;
    var ow = $(".widgetsContent").width();
    if(ow < x){
      return true
    }else{
      return false
    }
  }
  // updatePeriodIdValue(index:any,currentId,inUpdetedPeriodId){
  //   if(index > 0){
  //     this.updetedPeriodId = this.getNextId(inUpdetedPeriodId);
  //   }else{
  //     this.updetedPeriodId = currentId;
  //   }
  // }
  // calculateWidth(n){
  //   return 100/n;
  // }
  fullScreenExit() {
    var element = document.getElementById("bodyMain");
    var element2 = document.getElementById("fullScreenBtn");
    element.classList.remove("fullScreenOn");
    element2.classList.remove("active");
  }

  setSideBar(){
    let src:any;
    src=localStorage.getItem("screenSize");

      if(src==1){
        var element = document.getElementById("bodyMain");
        var element2 = document.getElementById("fullScreenBtn");
        element.classList.toggle("fullScreenOn");
        element2.classList.toggle("active");
        document.getElementById('fullScreenBtn').hidden = false;
        document.getElementById('fullScreenBtnExit').hidden = true;
      }

  }
  updateRating(id,prating,taskId,pId){
    this.loading = true;
    var rating = $("#rangeupdate"+id).val();
    if(rating==""){
      rating = prating;
    }
    var commntes = $("#comment"+id).val();
    let data = {
      id: id,
      rating: rating,
      comment: commntes,

    };


    //call login api here
    this.commonService.addRatingAndCommentTask(data).subscribe(
      (result) => {
        // this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //location.reload();
          this.closeCommentPopup();
          this.ngOnInit();
          var width = $("#widgetsContent").width();

          this.totalboxinview = Math.floor(parseInt(width)/190)-1;



          this.pId_global = pId; 
          this.taskId_global = taskId; 
          

          /* // commented on 28-06-2022 as it fails when api takes more time 
          setTimeout(() => {
            //document.getElementById(taskId+"-"+pId+"-").scrollIntoView();

            this.periodPosition = $("#"+pId).parent().parent().children().index($("#"+pId).parent());

            console.log("this.periodPosition *** ", this.periodPosition); 

            if(this.periodPosition>this.totalboxinview){
            var itrationcount = this.periodPosition-this.totalboxinview;
            console.log(itrationcount);
            var i;
              for (i = 0; i < itrationcount; i++) {
                this.scrollRight();
              }
            }


          }, 21000);

          setTimeout(()=>{
            $('html,body').animate({
              scrollTop: ($("#"+taskId+"-"+pId+"-").offset().top-50)},
              'fast');
          },21000); 

            $("#"+taskId+"-"+pId+"- .ratedBoxOuter").click();
          */ 
        
          this.feedback.text = "";
          this.message = result["data"].ResponseMessage;
        } else {

          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {}
    );
  }
  dateFormates() {
    var formatDateComponent = function (dateComponent) {
      return (dateComponent < 10 ? '0' : '') + dateComponent;
    };
    let current_datetime = new Date();
    let formatted_date =formatDateComponent(current_datetime.getDate())+'-'+
      formatDateComponent(current_datetime.getMonth() + 1) + '-' + current_datetime.getFullYear();
    return formatted_date;
  }
  exitwindow(){
    if(confirm("Your work is saved. You can re-enter the application if required.")){
      this.router.navigateByUrl("/dashboard");
    }
    //if(alert("your work is saved.You can re-enter the application if required")){

    //}
  }
  calcLength(obj:any){
    var keys = Object.keys(obj);
    return keys.length;
  }





  scrollOnDataLoad(pId = 0, taskId = 0, InitativeId = ''){  // created on 28-06-2022 to call this function when project data load as currently setTimeOut is used which fails some time if api takes more time

    console.log("InitativeId , "+InitativeId+" taskId, "+taskId+"pId +++++++++++++++++++ ", pId); 
    



    // horrizontal scrolling start 
    
    if(InitativeId && pId){
        setTimeout(() => {
          document.getElementById(InitativeId).scrollIntoView();
          // console.log("if block 1 offset ** ", $("#"+pId).offset()); 
          this.periodPosition = $("#"+pId).parent().parent().children().index($("#"+pId).parent());
          if(this.periodPosition>this.totalboxinview){
          var itrationcount = this.periodPosition-this.totalboxinview;
          var i;
            for (i = 0; i < itrationcount; i++) {
              this.scrollRight();
            }
          }           
        }, 500);
    } else if(pId){
      setTimeout(() => {
        //document.getElementById(taskId+"-"+pId+"-").scrollIntoView();
        // console.log("if block 2 offset ** ", $("#"+pId).offset()); 
        this.periodPosition = $("#"+pId).parent().parent().children().index($("#"+pId).parent());
        if(this.periodPosition>this.totalboxinview){
        var itrationcount = this.periodPosition-this.totalboxinview;
        var i;
          for (i = 0; i < itrationcount; i++) {
            this.scrollRight();
          }
        }
      }, 500);  
    }

    // horrizontal scrolling end

    // vertical scrolling start 
    if(taskId && pId){
      setTimeout(()=>{
        console.log("1st if block - taskId && pId ** ", $("#"+taskId+"-"+pId+"-").offset()); 
        $('html,body').animate({
          scrollTop: ($("#"+taskId+"-"+pId+"-").offset().top-200)
        },
          'fast');
      }, 600); 
      $("#"+taskId+"-"+pId+"- .ratedBoxOuter").click();
    } else if(InitativeId){
      setTimeout(()=>{
        console.log("2nd if block - InitativeId ** ", $("#"+InitativeId).offset()); 
        $('html,body').animate({
          scrollTop: $("#"+InitativeId).offset().top - 200, // ($("#"+taskId+"-"+pId+"-").offset().top-50)
        },
          'fast');
      }, 600); 
      // $("#"+taskId+"-"+pId+"- .ratedBoxOuter").click();
    } else if(pId){
      setTimeout(()=>{
        console.log("3rd if block - pId ** ", $("#"+pId).offset()); 
        $('html,body').animate({
          scrollTop: $("#"+pId).offset().top - 200, // ($("#"+taskId+"-"+pId+"-").offset().top-50)
        },
          'fast');
      }, 600); 
      // $("#"+taskId+"-"+pId+"- .ratedBoxOuter").click();
    }

    // vertical scrolling end 


    // below code commented and above code added for vertical scrolling on 22-07-2022 as was not perfectly working 
    // if(taskId && pId){
    //   setTimeout(()=>{
    //     $('html,body').animate({
    //       scrollTop: ($("#"+taskId+"-"+pId+"-").offset().top-50)},
    //       'fast');
    //   }, 500); 
    //   $("#"+taskId+"-"+pId+"- .ratedBoxOuter").click();
    // } 



  }


}



