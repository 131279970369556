<app-growler>
</app-growler>
<app-header></app-header>
<div class="wrapper">
    <div class="loaderOuter" *ngIf="loading">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <h3 class="breadCrumb"> <a class="profileIcn" herf="javascript:void(0)" (click)="cancleEdit()">Clients</a> <span *ngIf="htmlOptions.editMode"><a herf="javascript:void(0)">>Edit</a></span></h3>
                    <button *ngIf="htmlOptions.editMode" class="btn btn-primary themeBtnBlue" (click)="cancleEdit()">Back</button>
                </div>
            </div>
        </div>

        <div class="white_box-body minheight100vh" *ngIf="!htmlOptions.editMode">
            <div class="client-outer pt-3">
                <div class="row justify-content-center mb-3">
                    <div class="col-md-5">
                        <!-- <ng-select class="form-control" [items]="cities" bindLabel="name" placeholder="Search for an organisation" [(ngModel)]="selectedCity">
                        </ng-select> -->
                        <div class="dropdown searchOrgInput">
                            <a href="javaScript:void(0)" class="crossIconOuter" (click)="getAllUserOnSearchButton()">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </a>
                            <!-- <a *ngIf="isSearched" href="javaScript:void(0)" class="crossIconOuter" (click)="resetAllUserOnSearchButton()">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </a> -->
                            <input id="searchKey" (blur)="getAllUserOnSearch($event)" (keypress)="getAllUserOnEnter($event)" class="form-control dropdown-toggle" placeholder="Search for an organisation">
                            <a href="javascript:void(0)" id="orgSearchBoxClick" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                            <a href="javascript:void(0)" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></a>
                            <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody *ngIf="userOrgData">
                                            <tr *ngFor="let user of userOrgData">

                                                <td>
                                                    <a (click)="getAllUserBykeyWord(user.id)" href="javascript:void(0);">
                                                        <strong>{{user.name}}</strong>

                                                    </a>

                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="userOrgData">
                                            <tr>
                                                <td>
                                                    <a href="javascript:void(0);">
                                                        <strong>No Records Found</strong>

                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="normal_table-outer client_table-outer">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th width="30px" class="br-bottomNone"></th>
                                            <!-- <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th> -->
                                            <th></th>
                                            <th>Organisation</th>
                                            <th># SOP’s</th>
                                            <th># SFR’s</th>
                                            <th># Participants</th>
                                            <th>Principal</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let org of orgData; let i=index">
                                            <tr>
                                                <td class="bg-white"> <a class="clientColArrow" [ngClass]="{'ArrowUpDown' : !org.visible}" (click)="org.visible=!org.visible" href="javaScript:void(0)"><i class="fa fa-caret-right"></i></a> </td>
                                                <td><input type="checkbox" value="{{org.id}}" [(ngModel)]="orgData[i].checked"></td>
                                                <td><a href="javascript:void(0)" (click)="editUser(org.projectId)">{{org.name}}</a></td>
                                                <td>{{org.totalSop}}</td>
                                                <td>{{org.totalSfr}}</td>
                                                <td>{{org.totalOrgUser}}</td>
                                                <td>
                                                    <span *ngFor="let principal of changeObjToArrayPrincipal(org.principal); let i =index">
                                                        <span *ngIf="i>0">|</span>{{principal}}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span *ngIf="!org.status">Inactive</span>
                                                    <span *ngIf="org.status">Active</span>
                                                </td>
                                            </tr>
                                            <tr class="clientMore-body" *ngIf="org.projects" [ngClass]="{'d-none' : !org.visible}">
                                                <td class="bg_white"></td>

                                                <td colspan="5" class="bg_white inner_client-table">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Project</th>
                                                                <th>Type</th>
                                                                <th>Individual / Group</th>
                                                                <th>Owner</th>
                                                                <th>Status</th>
                                                                <th>Unarchive</th>
                                                                <th># Participants</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="org.projects">
                                                            <ng-container *ngIf="org.status">

                                                                <!-- <span *ngIf="1 | RecordCnt"> some text</span> -->
                                                                <tr *ngFor="let project of org.projects; let i =index">
                                                                    <td>
                                                                        <div class="round">
                                                                            <input id="{{project.projectId}}" type="checkbox" value="{{project.projectId}}" [(ngModel)]="org.projects[i].checked"/>
                                                                            <label for="{{project.projectId}}"></label>
                                                                          </div>

                                                                        <!-- <input type="checkbox" value="{{project.projectId}}" [(ngModel)]="org.projects[i].checked"> -->
                                                                    </td>
                                                                    <td>
                                                                        <a href="javascript:void(0)" (click)="goToProjectView(project.projectId,project.isGroup,project.projectType,project)">{{project.title}}</a>

                                                                    </td>
                                                                    <td>
                                                                        <span *ngIf="project.projectType == 1"> Strategic Operating Plan</span>
                                                                        <span *ngIf="project.projectType == 2"> Stakeholder Feedback Report</span>
                                                                    </td>
                                                                    <td>
                                                                        <img *ngIf="project.isGroup == 1" src="./assets/images/individual-sop-icon.png" alt="icon" width="20px">
                                                                        <img *ngIf="project.isGroup == 2" src="./assets/images/group-sop-icon.png" alt="icon" width="20px">
                                                                    </td>
                                                                    <td>{{project.owner}}</td>
                                                                   
                                                                    <td>

                                                                        <span *ngIf="!project.status && !project.is_deleted">Inactive</span>
                                                                        <span *ngIf="project.status && project.is_deleted">Archived</span>
                                                                        <span *ngIf="project.status && !project.is_deleted">Active</span>
                                                                    </td>

                                                                    <td>
                                                                        <span *ngIf="project.is_deleted && project.status" > <a href="javascript:void(0)" (click)="reviveProject(project.projectId,project.projectType,project)"><img src="./assets/images/rotate-left.svg" alt="icon" width="20px"></a></span>
                                                                        <span *ngIf="project.status && !project.is_deleted">-</span>
                                                                        <span *ngIf="!project.status && !project.is_deleted">-</span>
                                                                    </td>
                                                                    <td>{{project.totalPrarticipant}}</td>
                                                                </tr>
                                                            </ng-container>
                                                            <ng-container *ngIf="!org.status">
                                                                <tr>
                                                                    <td colspan="6">
                                                                        No Records found
                                                                    </td>
                                                                </tr>
                                                            </ng-container>

                                                        </tbody>

                                                        <tbody *ngIf="!changeObjToArray(org.projects).length">
                                                            <tr>
                                                                <td colspan="6">
                                                                    No Records found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>

                                                <td class="bg_white"></td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                                <div class="text-left mb-2">
                                    <button class="btn btn-primary" [disabled]="isAllCheckBoxNotCheckedProject()" (click)="deleteProjects()">Delete Selected Projects</button>
                                </div>
                                <div class="text-left">
                                    <button class="btn btn-primary" [disabled]="isAllCheckBoxNotChecked()" (click)="deleteOrgnations()">Delete Selected Organisations</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="white_box-body minheight100vh" *ngIf="htmlOptions.editMode">

            <div class="client_detail-outer pt-3">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="my-profileView-left">
                            <div class="media mb-3">
                                <div class="profileimgEdit">
                                    <label for="editProfilePic">
                                        <img *ngIf="Client.profileImage" [src]="Client.profileImage" width="300" />
                                        <img *ngIf="!Client.profileImage" src="assets/images/dummy-img.png" width="300" />

                                    </label>
                                    <label for="editProfilePic"><i for="editProfilePic" class="fa fa-upload"></i> Upload Photo</label>
                                    <input type="file" class="" id="editProfilePic" (change)="fileEvent($event, 'profile_image')" name="profile_image" accept="image/*">
                                </div>
                                <div class="media-body ">

                                    <div class="row">

                                        <div class="col-md-12">
                                            <input class="hideBlock form-control" id="org2title" maxlength="80" type="text" [(ngModel)]="Client.title" #user_name="ngModel" (blur)="updateOrgData()" placeholder="Enter Organisation Name">
                                            <h2 class="mb-3" id="org1title"> <span> {{Client.title}} </span> <a href="javascript:void(0)" (click)="OpenCloseDiv('title',  Client.title)"><i class="fa fa-pencil"></i></a></h2>
                                        </div>
                                        <div class="col-md-6">
                                            <label>WEBSITE URL</label>
                                            <textarea [(ngModel)]="Client.webUrl" #webUrl="ngModel" rows="1" class="form-control" (blur)="updateOrgData()" placeholder="Enter Website Url"></textarea>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>COMPANY RELATIONSHIP MANAGER</label>

                                                <select class="form-control" name="relationshipManager" id="relationshipManager" [(ngModel)]="Client.relationshipManager" #relationshipManager="ngModel" (change)="changeManager($event)">
                                                        <option [value]="0">-Please Select-</option>
                                                        <option *ngFor="let pricipal of principalData" [value]="pricipal.id">
                                                            {{pricipal.userName}}</option>
                                                    </select>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row align-items-center">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>SUMMARY OF ENGAGEMENT</label>
                                                <textarea [(ngModel)]="Client.description" #description="ngModel" rows="4" class="form-control" (blur)="updateOrgData()" placeholder="Enter Organisation Name"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-md-6">

                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1" [(ngModel)]="Client.status" #status="ngModel" name="status" (click)='updateOrgStatus($event);'>
                                                <label class="custom-control-label" for="customCheck1">Organisation Active?</label>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

        </div>

        <div class="white_box-body minheight100vh mt-3" *ngIf="htmlOptions.editMode">

            <div class="client_detail-outer pt-3">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="mb-2">Client Users Across Projects</h2>
                        <div class="table-responsive normal_table-outer client_Users_AP-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Title</th>
                                        <th>Email Address</th>
                                        <th>User Active?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of orguser">
                                        <td>{{user.userName}}</td>
                                        <td>{{user.title}}</td>
                                        <td>{{user.email}}</td>
                                        <td>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck{{user.id}}" (click)="updateUserStatus(user.id, user.status)" [checked]='user.status'>
                                                <label class="custom-control-label" for="customCheck{{user.id}}"></label>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <input id="userName" [(ngModel)]="addOrguserData.userName" #userName="ngModel" type="text" (blur)="addOrganisationUser(Client.id, $event.target.value)" placeholder="+ Add User">
                                        </td>
                                        <td><input id="userTitle" [(ngModel)]="addOrguserData.title" #title="ngModel" type="text" (blur)="addOrganisationUser(Client.id,$event.target.value)" placeholder="Title"></td>
                                        <td><input id="userEmail" [(ngModel)]="addOrguserData.email" #email="ngModel" type="text" (blur)="addOrganisationUser(Client.id,$event.target.value)" placeholder="Email Address"></td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>