import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CommonService } from "../../services/commonservices";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GrowlerService } from "../../services/growler.service";
import { Profile } from "../../model/profile";
import { environment } from "../../../environments/environment";
import * as AWS from "aws-sdk";
import * as $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { IoT1ClickDevicesService } from "aws-sdk";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialougModalComponent } from "src/app/confirmation-dialoug-modal/confirmation-dialoug-modal.component";
import { ModalComponent } from "src/app/modal/modal.component";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  @Input() newLogo: string;
  public sectionToggle1: boolean = true;
  public sectionToggle2: boolean = true;
  public showLoder: boolean = true;
  globalFilesArray: any = [];
  savePermissionData: any = {};
  notValid: boolean = false;
  validTrue: boolean = false;
  public user: any = {};
  htmlOptions: any = {};
  organizationList: any = {};
  originalorgList: any = {};
  seconedary_orgList: any = {};
  originalseconedary_orgList: any = {};
  isSearched: boolean = false;
  loading: boolean = false;
  message: any;
  logoImg: any = "";
  permissionData: any = [];
  tempPermissionData: any = [];
  userData: any = [];
  userOrgData: any = [];
  userRoles: any = [];
  notesdata: any;
  message1: any;
  totalPageArr: any = [];
  totalPage: any;
  currentPage: number = 1;
  modalVisible = false;
  //getActiveProjectDashboard:object;

  isLoggedIn: any;

  selectedCars = [3, 4];
  cars = [
    { id: 1, name: "Volvo" },
    { id: 2, name: "Saab" },
    { id: 3, name: "Opel" },
    { id: 4, name: "Audi" },
  ];
  cities = [
    { id: 1, name: "Vilnius" },
    { id: 2, name: "Kaunas" },
    { id: 3, name: "Pavilnys", disabled: true },
    { id: 4, name: "Pabradė" },
    { id: 5, name: "Klaipėda" },
  ];
  selectedCity: any;
  logs: any;
  selectedCityIds: string[];
  selectedCityName = "Vilnius";
  selectedCityId: number;
  globalRoles: any = [];
  selectedUserIds: number[];
  public Profile: Profile = {} as Profile;
  secondaryorgList: any=[];
  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public growlerService: GrowlerService,
    public router: Router,
    public modalService: NgbModal,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    let roles = localStorage.getItem("roles");
    this.getPermissionByRoleGlobal(roles);
    this.logoImg = localStorage.getItem("logo");
    this.showLoder = false;
    this.getSetting();
    this.getAllUser();
    this.GetUserRoles();
    this.getLogs(this.currentPage);
    this.htmlOptions.viewPermission = false;
    this.getOrganizationList();
  }
  getOrganizationList() {
    this.loading = true;
    let data = {
      orgId: "",
      role: 4,
    };
    this.commonService
      .getOrgByUser(data)
      .subscribe((organizationResult) => {
        this.loading = false;
        if (!organizationResult["success"]) {
          this.message = organizationResult["error"];
          this.toastrService.error(this.message);
        }
        if (
          organizationResult["success"] &&
          organizationResult["data"].ResponseCode == 200
        ) {
          this.organizationList = organizationResult["data"].ResponseData;

          this.originalorgList = organizationResult["data"].ResponseData;
          

          this.seconedary_orgList = organizationResult["data"].ResponseData;

          this.originalseconedary_orgList = organizationResult["data"].ResponseData;

          
          //this.getUserRoleList();
        } else {
          this.message = organizationResult["data"].ResponseMessage;
        }
      });
  }

 

  userDetails(userId) {
    console.log(this.globalRoles);
    if (this.globalRoles && !this.globalRoles.global_user_details) {
      alert("You do not have permissions to update User details.");
      return false;
    }
    this.getUserData(userId);

    document.getElementById("userModal").click();
  }
  getUserData(userId) {
    let data = {
      userId: userId,
      allProject:1
    };

    this.secondaryorgList =[];

    this.loading = true;
    this.commonService
      .apiCall(data, "getUserData")
      .subscribe((activeProjectResult) => {
        this.loading = false;
        if (!activeProjectResult["success"]) {
          this.message = activeProjectResult["error"];
        }
        if (
          activeProjectResult["success"] &&
          activeProjectResult["data"].ResponseCode == 200
        ) {
          this.Profile = activeProjectResult["data"].ResponseData;

          this.Profile.secondary_org_id=activeProjectResult["data"].ResponseData.secondary_org_id ? 
          activeProjectResult["data"].ResponseData.secondary_org_id.split(',').map(Number) : null;

       if(this.Profile.secondary_org_id && this.Profile.secondary_org_id.length > 0){
          this.secondaryorgList=this.Profile.secondary_org_id;
       }

       this.organizationList = this.organizationList.filter(item => !this.secondaryorgList.includes(item.id));

       this.seconedary_orgList = this.seconedary_orgList.filter(item => item.id !== activeProjectResult["data"].ResponseData.organization_id);

          this.htmlOptions.cpassword = "";
        } else {
          this.message = activeProjectResult["data"].ResponseMessage;
        }
      });
  }
  addOrg(e: any) {
    // Check if the maximum number of organizations has been reached
    if (this.Profile.secondary_org_id.length > 4) {
      // Prevent the new value from being added
      alert('You can only add up to 4 organizations.');
  
      // Reset the ngModel to prevent the value from being added
      this.Profile.secondary_org_id = this.Profile.secondary_org_id.slice(0, 4);
      
      this.secondaryorgList=this.Profile.secondary_org_id;

      console.log('Organization added: if ', this.secondaryorgList);

      return;
    }
    else{
  // Add the organization to the list
  this.secondaryorgList.push(e);

  this.organizationList = this.organizationList.filter(item => item.id != e);

    }
  
  
  }
  
  removeOrg(e: any) {
    // Remove the organization from the list
    this.Profile.secondary_org_id = this.Profile.secondary_org_id.filter(org => org !== e.value);
    this.secondaryorgList = this.Profile.secondary_org_id;
    let foundObject = this.originalorgList.find(item => item.id == e.value);

    // If the object is found, push it to arraytest
    if (foundObject) {
      this.organizationList.push(foundObject);
    }

  }

  onOrganizationChange(event : any){

  
    // Step 1: Filter out the object with the specified id from originalArray
let filteredArray = this.originalseconedary_orgList.filter(item => item.id != event);
// Step 2: Replace contents of copyArray with filteredArray
this.seconedary_orgList.length = 0; // Clear copyArray
this.seconedary_orgList.push(...filteredArray); // Add all items from filteredArray

  }
  
  
  
  closeUserDetails(){
    this.ngOnInit();
  }
  onSubmitData() {
    var that = this;
    if (
      that.Profile.new_password &&
      that.htmlOptions.cpassword &&
      that.Profile.new_password != that.htmlOptions.cpassword
    ) {
      return;
    }
    if (that.Profile.password && that.Profile.new_password == "") {
      return;
    }
    if (!that.Profile.password && that.Profile.new_password) {
      return;
    }
    that.loading = true;
    that.Profile.profile_image = that.Profile.profile_image;
    that.saveData();
  }
  saveData() {
    var that = this;
    console.log("chgeck++")
    let data = {
      password: that.Profile.password,
      new_password: that.Profile.new_password,
      name: that.Profile.user_name,
      title: that.Profile.title,
      email: that.Profile.email,
      profileImage: that.Profile.profile_image,
      bio: that.Profile.bio,
      isStatusChanged: false,
      userId: that.Profile.id,
      organization_id: that.Profile.organization_id,
      secondary_org_id: that.Profile.secondary_org_id,
    };
    
    console.log("data++++",data);

    
    that.commonService.apiCall(data, "updateUserProfile").subscribe(
      (result) => {
        that.loading = false;
        if (!result["success"]) {
          
          that.message = result["error"];
          that.growlerService.error(that.message);
          that.growlerService.clear();

          document.getElementById("userClickHide").click();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          var msg = "User Updated successfully.";
          this.growlerService.success(msg);
          this.growlerService.clear();
          document.getElementById("userClickHide").click();
          
        } else {
          that.growlerService.error(result["data"].ResponseMessage);
          that.growlerService.clear();
          document.getElementById("userClickHide").click();
        }
      },
      (error) => {}
    );
  }
  onClose(isVisible: boolean) {
    this.modalVisible = isVisible;
  }

  readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        //$("#blah").attr("src", e.target.result).width(150).height(200);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
  fileEvent(fileInput: any, fileType) {
    this.readURL(fileInput);
    var files = fileInput.target.files;
    var file = files[0];
    if (file.size > 250000) {
      this.htmlOptions.logo = "";
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Image size should be less than 250 KB";
      this.globalFilesArray = [];
      return false;
    } else if (
      file.type == "image/jpeg" ||
      file.type == "image/JPEG" ||
      file.type == "image/png" ||
      file.type == "image/PNG" ||
      file.type == "image/jpg" ||
      file.type == "image/JPG"
    ) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.logoImg = reader.result;
      };
      this.globalFilesArray = file;
    } else {
      this.htmlOptions.logo = "";
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Only jpg, png and jpeg file format allowed";
      this.globalFilesArray = [];
      return false;
    }
  }
  onSubmit() {
    this.uploadFile(this.globalFilesArray);
  }
  uploadFile(file): Promise<any> {
    var self = this;
    this.loading = true;
    return new Promise<any>((resolve) => {
      const contentType = file.type;
      if (file.length == "0") {
        this.htmlOptions.logo = "";
        this.loading = false;
        this.notValid = true;
        setTimeout(() => {
          this.notValid = false;
        }, 4000);
        this.message = "Please upload a valid image";
        return "";
      }
      const timenow = new Date();

      const bucket = new AWS.S3({
        accessKeyId: "AKIA2CCQ2X6SLXNDTERL",
        secretAccessKey: "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",
        region: "ap-southeast-2",
      });
      let filename = "";
      const params = {
        Bucket: "dev-adelante-images/_development",
        Key: timenow.getTime() + "_" + file.name,
        Body: file,
        ACL: "public-read",
        ContentType: contentType,
      };
      bucket.upload(params, function (err, data) {
        if (err) {
          alert(err.message);
          return "";
        } else {
          self.validTrue = true;
          setTimeout(() => {
            self.validTrue = false;
          }, 4000);
          self.saveSettings(data.Location);
          self.loading = false;
          self.htmlOptions.logo = "";
          self.message = "Successfully uploaded file.";
          return resolve(params.Key);
        }
      });
    });
  }
  saveSettings(logoUrl) {
    let data = {
      id: 1,
      logo: logoUrl,
    };

    this.loading = true;
    //call login api here
    this.commonService.saveSettings(data).subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //this.ngOnInit();
          this.logoImg = result["data"]["ResponseData"].logo;
          localStorage.setItem("logo", this.logoImg);
          this.growlerService.success("Logo Updated Successfully");
          this.growlerService.clear();
          location.reload();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  getSetting() {
    this.loading = true;
    //call login api here
    this.commonService.getSetting().subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.logoImg = result["data"]["ResponseData"].logo;
          localStorage.setItem("logo", this.logoImg);
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  changeObjToArray(projectData) {
    console.log("project Data...", projectData);
    var project = Object.keys(projectData).map(function (personNamedIndex) {
      let project = projectData[personNamedIndex];
      if (project.userId) {
        project.checked = false;
      }
      console.log("project...", project);
      return project;
    });
    return project;
  }
  upadateLogo(logo) {}
  getPermissionByRole(roleId, permissionData) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          var self = this;
          let resultData = result["data"]["ResponseData"];
          console.log(resultData);
          permissionData.forEach(function (item, index) {
            if (item != undefined) {
              self.savePermissionData[item.key] = resultData[item.key]
                ? resultData[item.key]
                : false;
            }
          });
          this.message = result["data"].ResponseMessage;
        } else {
          this.message = result["data"].ResponseMessage;

          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  getPermission(roleId, roleName) {
    //this.savePermissionData = []
    let data = {
      roleId: roleId,
    };
    this.htmlOptions.viewPermission = true;
    this.htmlOptions.roleName = roleName;
    this.htmlOptions.roleId = roleId;
    this.loading = true;
    //call login api here
    this.commonService.apiCall(data, "getPermission").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          //this.permissionData = result["data"]["ResponseData"];
          this.permissionData = this.changeObjToArray(
            result["data"]["ResponseData"]
          );
          this.getPermissionByRole(roleId, this.permissionData);
          this.tempPermissionData = this.permissionData;
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  AddUserRole(userId: any, roleId: any) {
    this.loading = true;
    //call login api here
    let data = {
      userId: userId,
      roleId: roleId,
    };
    let roles = localStorage.getItem("roles");
    console.log(roles);
    this.commonService.apiCall(data, "addUserRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          let newRoles = roles + "," + roleId;
          localStorage.setItem("roles", newRoles);
          this.growlerService.success("Role added Successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  removeUserRole(userId: any, roleId: any) {
    this.loading = true;
    //call login api here
    let data = {
      userId: userId,
      roleId: roleId.value,
    };
    let roleValue = roleId.value;
    let roles = localStorage.getItem("roles");
    this.commonService.apiCall(data, "removeUserRole").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          let newRoles = this.getUpdatedRoles(roles, roleValue, ",");
          localStorage.setItem("roles", newRoles);
          this.growlerService.success("Role removed Successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  getUpdatedRoles(list, value, separator) {
    separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }
  updateOrgStatus(userId: number, status: any) {
    if (this.globalRoles && !this.globalRoles.global_active_user) {
      alert("You do not have permissions to active/inactive User.");
      return false;
    }
    if (status) {
      if (
        confirm(
          "By de-activating this user they will not be able to log into their account."
        )
      ) {
        this.updateUserStatus(userId, status);
      } else {
        return false;
      }
    } else {
      this.updateUserStatus(userId, status);
    }
  }
  updateUserStatus(userId: number, status: any) {
    status = status ? "0" : "1";
    this.loading = true;
    //call login api here
    let data = {
      userId: userId,
      status: status,
    };
    this.commonService.apiCall(data, "updateUserStatus").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("Status updated Successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }

  originalUserDataArray: any=[];

    getAllUserBykeyWord(userId) {
    var self = this;
    var temp = [];
    var self = this;

    if (userId === undefined) {
      temp = [];
    } else if (userId) {

      this.userData=this.originalUserDataArray;
      this.userData.forEach(function (item) {
        if (item != undefined && item.userId == userId) {
          temp[0] = item;
          temp[0].checked = false;
        }
      });

      
    } else {
      temp = [];
    }
    this.userData = temp;
  }
  getAllUser() {
    this.loading = true;
    //call login api here
    var data = {
      searchKey: "",
    };
    this.commonService.apiCall(data, "getAllUser").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.userData = this.changeObjToArray(result["data"]["ResponseData"]);
          this.originalUserDataArray=this.userData;
          console.log("user data...", this.userData);
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  resetAllUserOnSearchButton() {
    // Reset the search input value
    $("#searchKey").val("");
    // Set the isSearched flag to false
    this.isSearched = false;
    // Fetch all users again
    this.getAllUser();
    // Hide the dropdown menu
    this.hideDropdown();
}

hideDropdown() {
    const dropdownMenu = document.getElementById("searchUserList");
    console.log("dropdownMenu+",dropdownMenu)
    if (dropdownMenu) {
        dropdownMenu.classList.remove("show");
    }
}


getAllUserOnEnter(event) {
  var searchKey = $("#searchKey").val();
  if (event.keyCode === 13) {
    this.getAllUserOnSearch(searchKey);
  }
}
  getAllUserOnSearchButton() {
    var searchKey = $("#searchKey").val();
    if (!searchKey) {
      this.isSearched = false;
      alert("Please enter text to search");
    }
    this.getAllUserOnSearch(searchKey);
  }
getAllUserOnSearch(searchKey: any) {
  var searchKey = $("#searchKey").val();
  this.isSearched = true;
  if (!searchKey) {
      this.isSearched = false;
      this.getAllUser();
  }

  if (searchKey) {
      this.loading = true;
      var data = { searchKey: searchKey };
      this.commonService.apiCall(data, "getAllUser").subscribe(
          (result) => {
              this.loading = false;
              if (!result["success"]) {
                  this.message = result["error"];
                  this.growlerService.error(this.message);
                  this.growlerService.clear();
              }
              if (result["success"] && result["data"].ResponseCode == 200) {
                  this.userOrgData = this.changeObjToArray(result["data"]["ResponseData"]);
                  document.getElementById("orgSearchBoxClick").click();
                  this.showDropdown();
              } else {
                  this.message = result["data"].ResponseMessage;
                  this.growlerService.error(result["data"].ResponseMessage);
                  this.growlerService.clear();
              }
          },
          (error) => {}
      );
  } else {
      this.isSearched = false;
  }
}


changeObjToArrayForOrg(arr, key) {
  let arrVal = this.changeObjToArray(arr);
  return arrVal[0]["orgnization"];
}

  getLogs(pageNo: any) {
    //this.loading = true;
    //call login api here
    var data = {
      pageNo: pageNo,
      limit: 10,
    };
    this.commonService.apiCall(data, "getLogs").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.logs = result["data"]["ResponseData"];
          this.totalPage = Math.ceil(
            parseInt(result["data"]["ResponseData"][0]["totalRecords"]) / 10
          );
          //this.totalPage = 30
          this.totalPageArr = Array(this.totalPage)
            .fill(0)
            .map((x, i) => i);
          //if(this.totalPageArr.length>10){
          let start = 0;
          if (this.currentPage >= 10) {
            start =
              (Math.floor(this.currentPage / 10) - 1) * 10 +
              (this.currentPage % 10) +
              1;
            if (start >= this.totalPage - 10) {
              start = this.totalPage - 10;
            }
          }
          this.totalPageArr = this.totalPageArr.splice(start, 10);
          // console.log(this.totalPage);
          //}
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }

  showDropdown() {
    const dropdownMenu = document.getElementById("searchUserList");
    if (dropdownMenu) {
      dropdownMenu.classList.add("show");
    }
  }


  GetUserRoles() {
    this.loading = true;
    //call login api here
    var data = {
      searchKey: "",
    };
    this.commonService.apiCall("", "getUserRoles").subscribe(
      (result) => {
        this.loading = false;
        if (!result["success"]) {
          this.message = result["error"];
          this.growlerService.error(this.message);
          this.growlerService.clear();
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.userRoles = result["data"]["ResponseData"];
        } else {
          this.message = result["data"].ResponseMessage;
          this.growlerService.error(result["data"].ResponseMessage);
          this.growlerService.clear();
        }
      },
      (error) => {}
    );
  }
  getSelectedRoles(userRoles) {
    var temp = [3];
    return temp;
    var self = this;
    if (userRoles === undefined) {
      temp = [];
    } else if (userRoles) {
      userRoles.forEach(function (item) {
        //temp.push(item.roleid);
      });
    }
    return temp;
  }

  addQuestion(e: any) {
    if (e != "") {
      let id = "";
      var description = "";
      if (this.notesdata) {
        description = this.notesdata;
      }
      this.addUpdateRole(e, description, id);
    } else {
      this.notValid = true;
      setTimeout(() => {
        this.notValid = false;
      }, 4000);
      this.message = "Please enter a valid Role Title";
    }
  }
  addQuestionNotes(id: any, que: any, notes: any) {
    if (notes != "") {
      let description = "";
      this.addUpdateRole(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  updateQuestion(id: any, notes: any, que: any) {
    if (que != "") {
      let description = "";
      this.addUpdateRole(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  UpdateQuestionNotes(id: any, que: any, notes: any) {
    if (notes != "") {
      let description = "";
      this.addUpdateRole(que, notes, id);
      this.notesdata = "";
    } else {
    }
  }
  addQuestionNotesSecond(notes: any) {
    if (notes != "") {
      let description = "";
      this.notesdata = notes;
    } else {
      this.notesdata = "";
      /* this.notValid1 = true;
      setTimeout(() => {
        this.notValid1 = false;
      }, 4000);
      this.message1 = "Please enter a valid Question notes"; */
    }
  }
  addUpdateRole(question, description, id) {
    let data = {
      id: id,
      title: question,
      description: description,
    };

    this.loading = true;
    //call login api here
    this.commonService.apiCall(data, "addUpdateRole").subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.notesdata = "";
          $("#questionBox").val("");
          $("#answerBox").val("");
          this.growlerService.success("Role/descrption added successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.notValid = true;
          setTimeout(() => {
            this.notValid = false;
          }, 4000);
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  OpenCloseDiv(queId, queText) {
    $("#que2" + queId).show();
    $("#que1" + queId).hide();

    $("#que2" + queId).val(queText);
  }
  OpenCloseDivAns(queId, notesText) {
    $("#ans2" + queId).show();
    $("#ans1" + queId).hide();

    $("#ans2" + queId).val(notesText);
  }
  assignPermissionToRole(roleId) {
    //return false
    let data = {
      roleId: roleId,
      permission: this.savePermissionData,
    };

    this.loading = true;
    //call login api here
    this.commonService.apiCall(data, "assignPermissionToRole").subscribe(
      (result) => {
        this.loading = false;
        this.notesdata = "";
        if (!result["success"]) {
          this.message = result["error"];
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.growlerService.success("Role updated successfully");
          this.growlerService.clear();
          this.ngOnInit();
        } else {
          this.message = result["data"].ResponseMessage;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  closePermission() {
    this.htmlOptions.viewPermission = false;
  }
  getPermissionByRoleGlobal(roleId) {
    var data = {
      roleId: roleId,
    };
    this.commonService.apiCall(data, "getPermissionByRole").subscribe(
      (result) => {
        if (!result["success"]) {
        }
        if (result["success"] && result["data"].ResponseCode == 200) {
          this.globalRoles = result["data"].ResponseData;
          if (this.globalRoles && !this.globalRoles.global_manage_setting) {
            alert("You do not have permissions to change settings.");
            this.router.navigateByUrl("/dashboard");
          }
          //localStorage.setItem("globalRoles", this.globalRoles);
        } else {
          localStorage.setItem("statusCode", result["data"].ResponseCode);
        }
      },
      (error) => {}
    );
  }
  callPagination(pageNumber: any) {
    if (pageNumber > this.totalPage || pageNumber < 1) {
      return false;
    } else {
      this.currentPage = pageNumber;
      this.getLogs(pageNumber);
    }

    //console.log(pageNumber);
  }

  checkAllCheckBox(ev: any) {
    this.userData.forEach((x) => (x.checked = ev.target.checked));
    this.originalUserDataArray=this.userData;
  }

  isAllCheckBoxChecked() {
    return this.userData.every((p) => p.checked);
   
  }

  isAllCheckBoxNotChecked() {
    return this.userData.every((p) => !p.checked);
    
  }

  deleteUsers() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      ConfirmationDialougModalComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.message =
      "Are you sure you want to delete the selected user(s). WARNING: DELETED DATA CANNOT BE RECOVERED";
    modalRef.result
      .then((result: any) => {
        console.log(result);
        if (result) {
          let users = this.userData.filter((p) => {
            return p.checked == true;
          });
          console.log("users...", users);
          let superUser: any = [];
          users.map((u) => {
            u.roles.map((susers) => {
              if (susers == 4 || susers == 15) {
                superUser.push(susers);
              }
            });
          });
          console.log("superUser...", superUser);
          if (superUser && superUser.length) {
            let ngbModalOptions: NgbModalOptions = {
              backdrop: "static",
              keyboard: false,
            };
            const modalRef = this.modalService.open(
              ModalComponent,
              ngbModalOptions
            );
            modalRef.result.then((result: any) => {
              console.log(result);
            });
          } else {
            let userId: any = [];
            for (let index = 0; index < users.length; index++) {
              userId.push(users[index].userId);
            }
            this.loading = true;
            this.commonService
              .apiCall({ userId: userId }, "removeUser")
              .subscribe(
                (activeProjectResult) => {
                  this.loading = false;
                  if (!activeProjectResult["success"]) {
                    this.message = activeProjectResult["error"];
                    this.toastrService.error(this.message);
                  }
                  if (
                    activeProjectResult["success"] &&
                    activeProjectResult["data"].ResponseCode == 200
                  ) {
                    this.growlerService.success(activeProjectResult["data"].ResponseMessage);
                    this.growlerService.clear();
                    this.ngOnInit();
                  } else {
                    this.message = activeProjectResult["data"].ResponseMessage;
                    this.toastrService.error(this.message);
                  }
                },
                (err) => {
                  console.log(err);
                  this.loading = false;
                  this.message = err.message;
                }
              );
          }
        }
      })
      .catch((result) => {
        console.log("cancelling");
      });
  }
}
