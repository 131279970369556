import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  NavigationEnd
} from "@angular/router";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class SopAuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;

    let  resetpwd = next.queryParamMap.get('resetpwd');

    if(!resetpwd || resetpwd =="null"){

      url = url.replace(/&resetpwd=[^&]+/, '');

      localStorage.setItem("SOP-Feedback",url);

      return this.verifyLogin(url);
    
    }
    else{

      url = url.replace(/&resetpwd=[^&]+/, '');

      localStorage.setItem("SOP-Feedback",url);

      this.router.navigate(['/resetpassword'], { queryParams: { token: resetpwd } });

      return false;

    }

    
    
  }

 


  verifyLogin(url): boolean {
    if (!localStorage.getItem("userId")) {
      this.router.navigate(["/"]);
      return false;
    }
    if (!this.isLoggedIn()) {
      this.router.navigate(["/"]);
      return false;
    } else if (this.isLoggedIn()) {
      return true;
    }
  }
  public isLoggedIn(): boolean {
    let status = false;
    if (
      localStorage.getItem("isLoggedIn") == "true") {

      status = true;
    } else {
      status = false;
    }
    return status;
  }
}
