<app-growler>
</app-growler>
<app-header></app-header>
<div class="loaderOuter" *ngIf="loading">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>
<div class="wrapper">
    <app-nav-bar></app-nav-bar>
    <div class="inner-container headerTopPaddipad">
        <div class="header_left-part">
            <div class="row">
                <div class="col-lg-10 col-xl-8">
                    <h3 class="breadCrumb"> <a class="profileIcn" herf="javascript:void(0)" (click)="cancleEdit()">My Profile</a> <span *ngIf="htmlOptions.editMode"><a herf="javascript:void(0)">>Edit</a></span></h3>
                    <button *ngIf="!htmlOptions.editMode" class="btn btn-primary themeBtnBlue" (click)="editUser()">Edit</button>
                </div>
            </div>
        </div>
        <div class="white_box-body minheight100vh">
            <div class="my_profile-outer pt-3" *ngIf="!htmlOptions.editMode">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="my-profileView-left">
                            <div class="media mb-3">
                                <img class="mr-3" *ngIf="Profile.profile_image" [src]="Profile.profile_image" width="300" />
                                <img class="mr-3" *ngIf="!Profile.profile_image" src="assets/images/dummy-img.png" width="300" />
                                <div class="media-body">
                                    <h4 class="mt-0"><strong>{{Profile.user_name}}</strong></h4>
                                    <p>{{Profile.title}}</p>
                                    <p><a href="mailto:{{Profile.email}}">{{Profile.email}}</a></p>
                                    <p><strong>{{Profile.orgnizationName}}</strong></p>
                                    <p>
                                        Bio:<br> {{Profile.bio}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="my-profileView-right">
                            <div class="row">
                                <div class="col-md-6 text-center">
                                    <i class="fa fa-building" aria-hidden="true"></i>
                                    <h4>{{Profile.orgnizationName}}</h4>
                                    <!-- <p><a href="https://www.chifleycorporation.com.au" target="_blank">www.chifleycorporation.com.au</a></p> -->
                                </div>
                                <div class="col-md-6 text-center">
                                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" slidesToShow="4" [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
                                        <div class="slide mb-3" ngxSlickItem *ngFor="let principal of Profile.principal">
                                            <div class="d-inline-block principleImg"><img src="assets/images/dummy-img.png" class="m-0" alt="..."></div>
                                            <h4>{{principal.user_name}}</h4>
                                            <p>Principal</p>
                                        </div>
                                    </ngx-slick-carousel>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6 text-center" *ngFor="let principal of Profile.principal">
                                            <div class="d-inline-block principleImg"><img src="assets/images/dummy-img.png" class="m-0" alt="..."></div>
                                            <h4>{{principal.user_name}}</h4>
                                            <p>Principal</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="my_profile-myproject-outer normal_table-outer">
                            <h2>My Projects</h2>
                            <div class="table-responsive" style="min-height: 200px;">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Project Name</th>
                                            <th>Type</th>
                                            <th>Individual/Group</th>
                                            <th>My role</th>
                                            <th>Owner</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let projects of Profile.projects | paginate: { itemsPerPage: 10, currentPage: p }">
                                            <td>
                                                <a *ngIf="!projects.is_deleted" href="javascript:void(0)" (click)="goToProjectView(projects.id,projects.is_group,projects.project_type,projects)">{{projects.title}}</a>
                                                <span *ngIf="projects.is_deleted">{{projects.title}}</span>
                                            </td>
                                            <td *ngIf="projects.project_type==1">SOP</td>
                                            <td *ngIf="projects.project_type==2">SFR</td>
                                            <td *ngIf="projects.is_group==1"><img src="./assets/images/individual-sop-icon.png" height="25">
                                            </td>
                                            <td *ngIf="projects.is_group==2"><img src="./assets/images/group-sop-icon.png" height="25"></td>
                                            <td *ngIf="projects.userRole">{{projects.userRole}}</td>
                                            <td *ngIf="!projects.userRole">-</td>
                                            <td>{{projects.ownerName}}</td>
                                            <td>
                                                <div class="profileTabDropdown"><span *ngIf="projects.is_deleted">Archived</span>
                                                    <span *ngIf="!projects.is_deleted">Active</span>
                                                    <a href="javaScript:void()" class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v" aria-hidden="true" *ngIf="!projects.is_deleted"></i>
                                                    </a>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item" (click)="goToProjectView(projects.id,projects.is_group,projects.project_type,projects)"><i
                                class="fa fa-pencil-square-o" aria-hidden="true"></i> View Project</a>

                                                        <a class="dropdown-item" (click)="goToProjectSetting(projects.id,projects.title,projects.project_type,projects)"><i
                                class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Project Setting</a>
                                                        <a class="dropdown-item" href="javascript:void(0)" (click)="copyProject(projects.id,projects.project_type, projects)"><i class="fa fa-clipboard"></i>Copy Project</a>



                                                        <a *ngIf="!projects.isDummyUser" class="dropdown-item" href="javascript:void(0)" (click)="addUser(projects.id,projects.organisation_id,projects.project_type, projects.title, projects)"><i class="fa fa-user-plus" aria-hidden="true"></i> Add user</a>
                                                        <a class="dropdown-item" href="javascript:void(0)" (click)="deleteProject(projects.id,projects.project_type,projects)"><i
                                class="fa fa-archive" aria-hidden="true"></i> Archive
                              project</a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="text-right" *ngIf="Profile.projects">
                                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="my_profile-outer my_profile-editMode pt-3" *ngIf="htmlOptions.editMode">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="my-profileView-left">
                            <form class="" name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()" novalidate>
                                <div class="media mb-3">
                                    <div class="profileimgEdit">
                                        <label for="editProfilePic">
                      <img *ngIf="Profile.profile_image" [src]="Profile.profile_image" width="300" />
                      <img *ngIf="!Profile.profile_image" src="assets/images/dummy-img.png" width="300" />

                    </label>
                                        <label for="editProfilePic"><i for="editProfilePic" class="fa fa-upload"></i> Upload Photo</label>
                                        <input type="file" class="" id="editProfilePic" (change)="fileEvent($event, 'profile_image')" name="profile_image" accept="image/*">
                                    </div>
                                    <div class="media-body">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>NAME<span class="req">*</span></label>
                                                            <input type="text" trim="blur" maxlength="80" class="form-control" placeholder="Enter Name" name="user_name" [(ngModel)]="Profile.user_name" #user_name="ngModel" required>
                                                            <div *ngIf="f.submitted && !user_name.valid" class="invalid-feedback-div">Name field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>JOB TITLE<span class="req">*</span></label>
                                                            <input type="text" trim="blur" maxlength="80" class="form-control form-control-line" placeholder="Enter Title" name="title" [(ngModel)]="Profile.title" #title="ngModel" required>
                                                            <div *ngIf="f.submitted && !title.valid" class="invalid-feedback-div">Title field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Email Address<span class="req">*</span></label>
                                                            <input [readonly]='true' type="text" trim="blur" maxlength="80" class="form-control form-control-line" placeholder="Enter Email" name="email" [(ngModel)]="Profile.email" #email="ngModel" required>
                                                            <div *ngIf="f.submitted && !email.valid" class="invalid-feedback-div">Email field is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>OLD PASSWORD </label>
                                                            <input type="password" class="form-control" maxlength="29" name="password" [(ngModel)]="Profile.password" #password="ngModel">
                                                            <div *ngIf="!Profile.password && Profile.new_password" class="invalid-feedback-div">Old Password field is required</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>NEW PASSWORD</label>
                                                            <input type="password" class="form-control" maxlength="29" name="new_password" [(ngModel)]="Profile.new_password" #new_password="ngModel" pattern="^[0-9,a-z,A-Z,@,#,$,^,!,%,&,*]{6,30}$" [required]="Profile.password">
                                                            <!--  <div *ngIf="f.submitted && !new_password.valid" class="invalid-feedback-div">New Password field is required</div> -->
                                                            <div *ngIf="new_password.errors && new_password.errors.pattern" class="invalid-feedback-signup">Password must contain at least 6 characters
                                                            </div>
                                                            <div *ngIf="f.submitted && Profile.password && !Profile.new_password && !new_password.errors.pattern" class="invalid-feedback-div">New Password field is required</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>RE-ENTER NEW PASSWORD</label>
                                                            <input type="password" class="form-control" maxlength="80" name="cpassword" [(ngModel)]="htmlOptions.cpassword" #cpassword="ngModel">
                                                            <div *ngIf="!htmlOptions.cpassword && Profile.new_password" class="invalid-feedback-div">Re-enter Password field is required</div>
                                                            <div *ngIf="(Profile.new_password && htmlOptions.cpassword && htmlOptions.cpassword != Profile.new_password)" class="invalid-feedback-div">Re-enter password doesn't match with password</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>BIO</label>
                                                    <textarea name="bio" rows="5" class="form-control" [(ngModel)]="Profile.bio"></textarea>
                                                </div>
                                            </div>

                                            <div class="col-md-12 text-right">
                                                <button class="btn btn-light ml-2" (click)="cancleEdit()"> Cancel </button>
                                                <button class="btn btn-primary mx-2 btn-Post-save btnInLIneRight"> Save </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</div>

<!--Add User for project assign -->
<input type="hidden" data-toggle="modal" data-target="#addUserModal" id="btnAddUser" placeholder="+ Add New User" class="addNewUserTextBox">
<div data-backdrop="false" class="modal fade" id="addUserModal" tabindex="-1" role="dialog" aria-labelledby="addUserModal" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title m-0" id="addUserModal">Add New User</h3>
                <button type="button" id="addNewUserHide" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body py-4">
                <div class="row">
                    <div class="col-md-12">
                        <label>Select User<span class="req">*</span></label>
                        <div class="input-group mb-2 mr-sm-2">

                            <ng-select class="form-control" [items]="searchUsers" bindValue="id" bindLabel="fullDetails" placeholder="Select user" [(ngModel)]="selectedUser">
                            </ng-select>


                            <div class="input-group-prepend">
                                <div class="input-group-text" data-toggle="modal" (click)="selectedUserAdd()">Add</div>
                            </div>

                        </div>

                        <div *ngIf="notSelected" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User field is required
                        </div>


                        <div class="py-6 buttonPOsSetBt">
                            <input type="text" readonly data-toggle="modal" (click)="addMoreUser()" placeholder="+ Add New User" class="addNewUserTextBox">
                            <div class="" *ngIf="showAddUserDiv">

                                <form class="" name="form" #forguser="ngForm" (ngSubmit)="forguser.form.valid && onAddUser(forguser.form)" novalidate>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Job Title<span class="req">*</span></label>
                                                <input trim="blur" type="text" maxlength="50" name="usertitle" [(ngModel)]="Adduserorganization.usertitle" #usertitle="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !usertitle.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User title field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Name<span class="req">*</span></label>
                                                <input trim="blur" type="text" maxlength="50" name="username" [(ngModel)]="Adduserorganization.username" #username="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !username.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User name field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>EMAIL <span class="req">*</span></label>
                                                <input type="text" trim="blur" maxlength="100" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="useremail" [(ngModel)]="Adduserorganization.useremail" #useremail="ngModel" required class="form-control" />
                                                <div *ngIf="forguser.submitted && !useremail.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>Email field is invalid
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>User Role<span class="req">*</span></label>
                                                <select class="form-control" name="userRole" id="userRole" [(ngModel)]="Adduserorganization.userRole" #userRole="ngModel" required name="userRole">
                          <option *ngFor="let role of roleList" [value]="role.id">
                            {{ role.role }}</option>
                        </select>
                                                <div *ngIf="forguser.submitted && !userRole.valid" class="invalid-feedback d-block"><i class="fa fa-exclamation-triangle"></i>User Role field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-right">
                                            <button type="submit" id="closeModal" class="btn btn-primary themeBtnBlue">Add User</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>