<!-- <app-growler>
</app-growler> -->
<div class="loaderOuter" *ngIf="loading">
    <div class="lds-ripple">
        <div></div>
        <div></div>
    </div>
</div>
<div class="loginOuter">
    <div class="row no-gutters">
        <div class="col-lg-6 themeBg">
            <div class="loginLogoSection text-center min-height100vh">
                <!-- <app-logo></app-logo> -->
                <img *ngIf="logoUrl" [src]="logoUrl" alt="logo">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="loginForm min-height100vh">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <!-- <app-logo></app-logo> -->
                        <img *ngIf="logoUrl" [src]="logoUrl" alt="logo" class="formTopLogoImg">
                        <p>Please log in using the credentials provided to you</p>
                    </div>

                    <div class="col-10 col-lg-8 col-xl-5 mt-4">
                        <form class="form-horizontal form-material" name="form" #f="ngForm"
                            (ngSubmit)="f.form.valid && onSubmit()" novalidate>
                            <div class="form-group">
                                <label for="exampleInputEmail1">E-mail</label>
                                <input type="text" maxlength="80" trim="blur" class="form-control fix-autofill"
                                    placeholder="Enter Email"
                                    pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="email"
                                    trim="blur" [(ngModel)]="user.email" #email="ngModel"
                                    [ngClass]="{ 'is-invalid': (f.submitted && !email.valid) || (email.errors && email.errors.pattern) }"
                                    required  (change)="onChangeEvent($event)">
                                <div *ngIf="f.submitted && !email.valid && !email.errors.pattern"
                                    class="invalid-feedback">
                                    <div>Email field is required</div>
                                </div>
                                <div *ngIf="email.errors && email.errors.pattern" class="invalid-feedback">
                                    <div>Please enter valid Email</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="exampleInputPassword1">Password</label>

                                <input type="password" placeholder="Enter Password" maxlength="80" class="form-control"
                                    name="password" [(ngModel)]="user.password" #password="ngModel" trim="blur"
                                    [ngClass]="{ 'is-invalid': f.submitted && !password.valid }" required>

                                <div *ngIf="f.submitted && !password.valid" class="invalid-feedback">
                                    <div>Password is required</div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isTrustedAfterLogin != undefined && isTrustedAfterLogin == 0">
                                <label for="exampleInputPassword1">OTP</label>
                                <div class="otpInputBox">
                                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)"
                                        (fill)="handleFillEvent($event)"></ngx-otp-input>
                                    <div class="invalid-feedback" *ngIf="valid == true">
                                        OTP is required &nbsp;
                                    </div>
                                </div>
                            </div>

                            <div class="row" >
                                <div class="col-md-6" >
                                    <div class="form-check d-flex align-items-center pl-0">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox"class="custom-control-input" id="is_trusted" name="is_trusted" (change)="onCheckboxChange($event)" [(ngModel)]="user.is_trusted" #is_trusted="ngModel">
                                            <label class="custom-control-label" for="is_trusted">Is Trusted</label>
                                        </div>
                                      </div>
                                </div>
                                <div class="col-md-6" >
                                    <div class="form-group text-right">
                                        <a routerLink="/forgot-password"><u>Forgot password</u></a>
                                    </div>
                                </div>
                            </div>
                           
                            <button type="submit" class="btn btn-primary btn-block themeBtnBlue mt-5">Log In</button>
                        </form>
                        <div *ngIf="notValid" class="alert alert-danger mt-2">
                            <strong>{{message}}.</strong>
                        </div>
                        <div *ngIf="sendOTP" class="alert alert-success mt-2">
                            <strong>{{message}}</strong>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div style="width:352px; max-width:100%;margin:auto">
                            <p>By accessing and using this system you are agreeing to The Adelante Group Terms and
                                Conditions of Use and our Privacy Policy.</p>
                            <p>See our website for details: <a target="_blank"
                                    href="http://www.theadelantegroup.com">www.theadelantegroup.com</a></p>
                        </div>

                    </div>



                </div>

            </div>
        </div>
    </div>
</div>